const namespaced = true;
export default {
  namespaced,
  state: {
    miniVariant: false,
    platform: "desktop",
    dev_mode: localStorage.getItem("dev_mode") === "true",
    notification: {
      show: false,
      timeout: 5000,
      text: null,
    },
    error: {
      show: false,
      timeout: 5000,
      object: null,
    },
  },
  actions: {
    notify: ({ commit }, payload) => {
      commit("SET_NOTIFICATION", { show: true, timeout: 5000, text: payload });
    },
    setPlatform: ({ commit }, payload) => {
      commit("SET_PLATFORM", payload);
    },
    error: ({ commit }, payload) => {
      commit("SET_ERROR", { show: true, timeout: 5000, object: payload });
    },
    setDevMode: ({ commit }, payload) => {
      commit("SET_DEV_MODE", payload);
    },
  },
  getters: {
    isMobile: (state) => {
      return state.platform === "mobile";
    },
    isDesktop: (state) => {
      return state.platform === "desktop";
    },
    devMode: (state) => {
      return state.dev_mode;
    },
  },
  mutations: {
    ["set_variant"]: (state, payload) => {
      state.miniVariant = payload;
    },
    ["SET_NOTIFICATION"]: (state, payload) => {
      state.notification = payload;
    },
    ["SET_ERROR"]: (state, payload) => {
      state.error = payload;
    },
    ["SET_PLATFORM"]: (state, payload) => {
      state.platform = payload;
    },
    ["SET_DEV_MODE"]: (state, payload) => {
      console.log(payload)
      localStorage.setItem("dev_mode", payload);
      state.dev_mode = payload;
    },
  },
};
