<template>
  <div class="mb-3">
    <v-row class="pa-3 pb-0">
      <v-col class="pb-0">
        <h5 class="text-h6 pb-0">Место проведения</h5>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >
          <v-select
              :items="countries"
              filled
              v-model="form.country"
              item-text="text"
              item-value="id"
              label="Страна проведения"
              hide-details
              dense
              :error-messages="errors"
          >
          </v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            name="Регион"
            :rules="isRussiaSelected ? 'required' : null"
            v-slot="{ errors }"
        >
          <v-autocomplete
              v-model="form.region"
              :items="regions.list"
              label="Регион"
              filled
              :loading="regions.loading"
              item-value="id"
              item-text="name"
              return-object
              hide-details
              dense
              :error-messages="errors"
              :disabled="!isRussiaSelected"
              @change="changeRegion"
          >
            <template v-slot:selection="data">
              {{ data.item.fullname }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.fullname }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            name="Муниципалитет"
            v-slot="{ errors }"
        >
          <v-autocomplete
              v-model="form.municipality"
              :items="municipalities.list"
              :search-input.sync="municipalities.search"
              placeholder="Начните вводить текст"
              label="Муниципалитет"
              filled
              :loading="municipalities.loading"
              item-value="id"
              item-text="name"
              no-filter
              return-object
              hide-details
              dense
              :error-messages="errors"
              :disabled="!isRussiaSelected"
          >
            <template v-slot:selection="data">
              {{ data.item.type }} {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.type }} {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              v-model="form.title_ru"
              filled
              search
              label="Название места проведения"
              dense
              counter="255"
              :error-messages="errors"
              :disabled="!isRuLanguageSelected"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              class="mr-md-2"
              v-model="form.title_en"
              filled
              search
              label="Название места проведения (EN)"
              dense
              counter="255"
              :error-messages="errors"
              :disabled="!isEnLanguageSelected"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              v-model="form.coordinates"
              filled
              search
              label="Координаты места"
              dense
              counter="255"
              :error-messages="errors"
              @change="coordinates()"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <ValidationProvider
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-text-field
              color="primary"
              class="mr-md-2"
              v-model="form.coordinates_name"
              filled
              search
              label="Название координат"
              dense
              hide-details
              :error-messages="errors"
          >
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" md="8" class="pb-0">
        <yandex-map :settings="mapSettings" :coords.sync="map.coords" :zoom="5" @click="markerSetup">
          <ymap-marker :marker-id="map.marker.id" :marker-type="map.marker.type" :coords="map.marker.coords"
                       :options="map.marker.options" @dragend="markerSetup"></ymap-marker>
        </yandex-map>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import debounce from "debounce";
import {api} from "@/api";

export default {
  name: 'festival-location-form-component',
  components: {ValidationProvider, yandexMap, ymapMarker},
  props: {
    festival: {
      type: Object,
      default: null
    },
    regionsData: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: function () {
        return {
          country: null,
          region: null,
          municipality: null,
          title_ru: null,
          title_en: null,
          coordinates: null,
          coordinates_name: null
        }
      }
    }
  },
  watch: {
    'map.marker.coords': function (newVal) {
      this.form.coordinates = newVal.join(',')
    },
    'municipalities.search': function (value) {
      if (value) this.getMunicipals(value, this);
    },
  },
  created() {
    if (this.formData.region) {
      this.form.region = this.formData.region;
    }

    if (this.formData.municipality) {
      this.form.municipality = this.formData.municipality;
      this.municipalities.list.push(this.formData.municipality);
    }

    if (this.formData.coordinates){
      this.map.coords = this.formData.coordinates.split(',')
      this.map.marker.coords = this.formData.coordinates.split(',')
    }

  },
  data() {
    return {
      countries: [{id: 'russia', text: 'Российская Федерация'}, {id: 'other', text: 'Не РФ'}],
      regions: {
        list: this.regionsData,
        loading: false,
        search: null
      },
      municipalities: {
        list: [],
        loading: false,
        search: null
      },
      mapSettings: {
        apiKey: process.env.VUE_APP_YANDEX_APIKEY,
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      map: {
        coords: [1,1],
        marker: {
          id: 'main',
          type: 'Placemark',
          coords: [1,1],
          options: {
            draggable: true
          }
        }
      },
      form: this.formData,
    }
  },
  computed: {
    isRussiaSelected(){
      return this.form.country === 'russia';
    },
    isRuLanguageSelected(){
      return this.festival.languages.includes('ru');
    },
    isEnLanguageSelected(){
      return this.festival.languages.includes('en');
    },
  },
  methods: {
    changeRegion() {
      this.form.municipality = null;
    },
    markerSetup(e) {
      let coords = [1, 1]
      if (e.get('coords')) {
        coords = e.get('coords')
      } else {
        coords = e.get('target').geometry.getCoordinates()
      }
      this.map.marker.coords = coords
    },
    getMunicipals: debounce((value, self) => {
      self.municipalities.loading = true;
      api.kladr.get({query: value, contentType: 'city', regionId: self.form.region.id})
          .then((response) => {
            self.municipalities.list = response.data.list;
            self.municipalities.loading = false;
          })
    }, 500),
    coordinates(){
      this.map.marker.coords = this.form.coordinates.split(',')
      this.map.coords = this.form.coordinates.split(',')
    }
  }
}
</script>

<style scoped>
.ymap-container {
  height: 250px;
}
</style>