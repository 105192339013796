<template>
  <div>
    <v-row class="pa-3">
      <v-col cols="12">
        <h5 class="text-h6 mb-3">Дополнительные разделы партнеров, спонсоров и т.д.</h5>
        <v-btn color="primary" @click="add()">Добавить раздел</v-btn>
      </v-col>

    </v-row>

    <div class="pa-3">
      <v-row  v-for="(partner, index) in partners" :key="index" >
        <v-col cols="12" lg="9">
          <div class="partner">
            <v-row class="pa-3 pb-0">
              <v-col cols="12" md="6">
                <ValidationProvider
                    :name="`Название_${index}_ru`"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-text-field label="Название" dense filled v-model="partner.title_ru"
                                :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                    :name="`Название_${index}_en`"
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-text-field label="Название EN" dense filled v-model="partner.title_en"
                                :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <interface-logos-component :logos-data="partner.logos" :logos.sync="partner.logos"></interface-logos-component>
              </v-col>
            </v-row>
            <v-row  class="pa-3 mt-0 pt-0">
              <v-col cols="12">
                <v-btn color="primary" text outlined @click="remove(index)">Удалить раздел</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import InterfaceLogosComponent from "@/views/Festival/components/interface/interface-logos-component";
import {ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';

export default {
  name: 'interace-partners-component',
  components: {InterfaceLogosComponent, ValidationProvider},
  props: {
    partnersData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      partners: this.partnersData
    }
  },
  methods: {
    add() {
      this.partners.push({
        title_ru: null,
        title_en: null,
        logos:
            [{
              order: null,
              link: null,
              image: null
            }]
      })
    },
    remove(index) {
      this.partners.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
  .partner {
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 3px;
  }
</style>