var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('festival-view-tabs-component'),(_vm.exercise)?_c('p',{staticClass:"headline pa-3 mb-0"},[_vm._v(_vm._s(_vm.exercise.title_ru || _vm.exercise.title_en))]):_vm._e(),_c('v-row',{staticClass:"pa-3 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){_vm.importDialog.show = true}}},[_vm._v("Импорт протокола ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.template.loading,"disabled":_vm.template.loading,"outlined":"","text":""},on:{"click":function($event){return _vm.downloadTemplate()}}},[_vm._v("Скачать шаблон ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"mr-md-2",attrs:{"color":"primary","filled":"","search":"","placeholder":"Поиск по УИН, ФИО","dense":""},on:{"keyup":_vm.search},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" mdi-magnify ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.form.loading,"server-items-length":_vm.table.meta.total,"options":_vm.table.options,"footer-props":{
            itemsPerPageOptions : [10,25,50,-1]
          }},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.inputMask),expression:"inputMask"}],attrs:{"filled":"","dense":"","loading":item.loading,"disabled":item.loading,"placeholder":_vm.placeHolder,"hide-details":""},on:{"blur":function($event){return _vm.setValue(item, $event)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}},(_vm.devMode)?{key:"item.points",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{attrs:{"filled":"","dense":"","loading":item.loading,"disabled":item.loading,"hide-details":""},on:{"blur":function($event){return _vm.setPoints(item, $event)}},model:{value:(item.points),callback:function ($$v) {_vm.$set(item, "points", $$v)},expression:"item.points"}}),_c('v-alert',{attrs:{"type":"warning"}},[_vm._v("Это поле доступно для редактирования, потому что включен режим разработчика ")])]}}:null],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"480"},model:{value:(_vm.importDialog.show),callback:function ($$v) {_vm.$set(_vm.importDialog, "show", $$v)},expression:"importDialog.show"}},[_c('v-form',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Импортировать протокол ")]),_c('v-card-text',[_vm._v("Загрузка результатов из таблицы")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Название","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-file-input',{ref:"file",staticClass:"ma-0 pa-0",attrs:{"label":"Выберите файл в формате .xlsx","hide-details":"","fille":"","dense":"","filled":"","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","error-messages":errors,"disabled":_vm.importDialog.loading},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.importDialog.loading),expression:"!importDialog.loading"}],attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){_vm.importDialog.show = false}}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.importDialog.loading,"loading":_vm.importDialog.loading},on:{"click":function($event){return _vm.importTemplate()}}},[_vm._v(" Импортировать ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }