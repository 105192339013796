<template>
<div class="pa-3 pb-0">
  <v-row>
    <v-col>
      <input type="checkbox" @change="devModeSwitch()" :checked="devMode"/>
      Включить режим разработчика
    </v-col>
  </v-row>
<v-divider></v-divider>
  <v-row class="pt-3">
    <v-col cols="6">
      <h4>Генерация фестиваля (Тест автодеплоя 2)</h4>
      <v-form>
        <v-text-field :disabled="this.forms.festivalGeneration.loading" type="number" label="Кол-во участников"  dense  filled v-model="forms.festivalGeneration.members_count"></v-text-field>
        <v-btn :loading="this.forms.festivalGeneration.loading" color="primary" @click="generateFestival()">Создать</v-btn>
        <p v-if="this.forms.festivalGeneration.loading">Жди может занять время</p>
      </v-form>
    </v-col>
  </v-row>

  <v-row>

  </v-row>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "@/api";

export default {
name: 'DeveloperIndex',
  data(){
    return {
      forms: {
        festivalGeneration: {
          members_count: 100,
          loading: false
        }
      }
    }
  },
  computed: {
    ...mapGetters('app', ['devMode']),
  },
  methods: {
    ...mapActions('app', ['setDevMode']),
    devModeSwitch(){
      this.setDevMode(!this.devMode)
    },
    async generateFestival(){
      this.forms.festivalGeneration.loading = true;
      try {
        await api.developer.festivals.generate(this.forms.festivalGeneration)
      } catch (e) {
        console.error(e);
      }
      this.forms.festivalGeneration.loading = false;
    }
  }
}
</script>

<style scoped>

</style>