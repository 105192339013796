<template>
  <v-banner two-line>
    <p class="headline">{{ festival.title }}</p>
    <p>Свод: <strong>{{ festival.svod.name }}</strong> от <strong>{{ festival.svod.started_at }}</strong> ID: <strong>{{ festival.svod.id }}</strong></p>
  </v-banner>
</template>

<script>
export default {
name: "svod-info-comonent",
  props: {
    festival: {
      type: Object,
      default: function (){
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>