<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card-title>
            {{ title }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider
                    name="Пользователь"
                    rules="required"
                    v-slot="{ errors }"
                >

                  <v-autocomplete
                      v-model="form.gto.selected"
                      :items="form.gto.list"
                      :search-input.sync="userSearchString"
                      label="Поиск по УИН или email"
                      placeholder="Начните вводить текст"
                      filled
                      :loading="form.gto.loading"
                      item-value="id"
                      item-text="name"
                      no-filter
                      return-object
                      hide-details
                      dense
                      :error-messages="errors"
                      :readonly="loading || isEdit"
                  >


                    <v-icon
                        slot="prepend-inner"
                    >
                      mdi-magnify
                    </v-icon>
                    <template v-slot:selection="data">
                      {{ data.item.name }} {{ data.item.surname }} {{ data.item.middlename }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }} {{ data.item.surname }} {{
                            data.item.middlename
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.id }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

            <template v-if="form.gto.selected" dense>

              <v-row>
                <v-col
                    cols="12"
                >
                  <v-alert
                      dense
                      outlined
                      v-show="form.gto.selected.already_exist"
                      type="error"
                  >
                    Пользователь уже существует
                  </v-alert>
                  <v-list-item-title><strong>{{ form.gto.selected.name }} {{ form.gto.selected.surname }}
                    {{ form.gto.selected.middlename }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-2">УИН {{ form.gto.selected.id }}</v-list-item-subtitle>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                >

                  <v-list-item-title class="pa-0"><strong>Уровень оператора</strong></v-list-item-title>
                  <v-radio-group
                      v-model="form.role"
                      row
                      class="mt-2"
                      hide-details
                      ref="role"
                      :readonly="loading"
                      @change="removeMunicipality()"
                  >
                    <v-radio
                        label="Региональный"
                        value="regional_operator"
                        :readonly="!$gates.hasPermission('users.edit.set.region')"
                    ></v-radio>
                    <v-radio
                        label="Муниципалитет"
                        value="municipal_operator"
                    ></v-radio>
                  </v-radio-group>

                </v-col>
              </v-row>

              <v-row>

                <v-col
                    cols="12"
                >
                  <ValidationProvider
                      name="Регион"
                      rules="required"
                      v-slot="{ errors }"
                  >
                    <v-autocomplete
                        v-model="form.regions.selected"
                        :items="regionsData"
                        label="Регион"
                        filled
                        item-value="id"
                        item-text="name"
                        placeholder="Начните вводить текст"
                        return-object
                        hide-details
                        @change="removeMunicipality"
                        dense
                        :error-messages="errors"
                        :readonly="loading || !$gates.hasPermission('users.edit.set.region')"
                    >
                      <template v-slot:selection="data">
                        {{ data.item.fullname }}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title> {{ data.item.fullname }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

              </v-row>

              <v-row>

                <v-col
                    cols="12"
                >
                  <ValidationProvider
                      name="Муниципалитет"
                      :rules="(isMunicipal) ? 'required' : ''"
                      v-slot="{ errors }"
                  >
                    <v-autocomplete
                        v-model="form.municipals.selected"
                        :items="form.municipals.list"
                        :search-input.sync="municipalSearchString"
                        label="Муниципалитет"
                        filled
                        :loading="form.municipals.loading"
                        item-value="id"
                        item-text="name"
                        no-filter
                        placeholder="Начните вводить текст"
                        return-object
                        hide-details
                        dense
                        :disabled="!isMunicipal || !form.regions.selected"
                        :error-messages="errors"
                        :readonly="loading"
                    >
                      <template v-slot:selection="data">
                        {{ data.item.type }} {{ data.item.name }}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.type }} {{ data.item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ data.zip }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-checkbox
                      v-model="form.user.access_analytics"
                      :label="'Доступ к разделу аналитика'"
                      hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </template>
          </v-card-text>


          <v-card-actions class="pa-4">
            <v-btn
                v-if="form.gto.selected"
                color="primary"
                :disabled="invalid || form.gto.selected.already_exist || loading"
                @click="submit()"
                :loading="loading"
            >
              {{ buttonLabel }}
            </v-btn>

            <v-btn
                color="primary"
                outlined
                text
                @click="dialog = false"
                v-show="!loading"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');
import debounce from 'debounce'
import errorHandler from '@/modules/errorHandler';

import {mapState, mapActions} from 'vuex';

import {api} from '@/api';

export default {
  name: 'create-edit-user',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    populateWith: {
      type: Object,
      default: () => ({})
    },
    regionsData: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    userSearchString: '',
    regionSearchString: '',
    municipalSearchString: '',
    form: {
      gto: {
        list: [],
        selected: null,
        loading: false
      },
      role: null,
      regions: {
        list: [],
        selected: null,
        loading: false,
      },
      municipals: {
        list: [],
        selected: null,
        loading: false
      },
      user: {
        access_analytics: null
      },

    }
  }),
  watch: {
    userSearchString(value) {
      if (value) this.getUsers(value, this);
    },
    municipalSearchString(value) {
      if (value) this.getMunicipals(value, this);
    },
    populateWith() {

      Object.assign(this.$data.form, this.$options.data().form)
      this.form.role = this.roles.regional_operator.name

      if (this.$gates.hasRole(this.roles.regional_operator.name)) {
        this.form.regions.selected = this.user.region;
        this.form.regions.list.push(this.user.region);
        this.form.role = this.roles.municipal_operator.name;
      }

      if (this.isEdit) {
        this.form.gto.selected = this.populateWith.profile;
        this.form.gto.list.push(this.populateWith.profile);

        this.form.regions.selected = this.populateWith.region;
        this.form.regions.list.push(this.populateWith.region);

        if (this.populateWith.municipality) {
          this.form.municipals.selected = this.populateWith.municipality;
          this.form.municipals.list.push(this.populateWith.municipality);
        }

        this.form.role = this.populateWith.role.name;
        this.form.user = this.populateWith;
      }
    },

  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('roles', ['roles']),
    isRegional() {
      return this.form.role === this.roles.regional_operator.name;
    },
    isMunicipal() {
      return this.form.role === this.roles.municipal_operator.name;
    },
    isEdit() {
      return (this.populateWith && Object.keys(this.populateWith).length > 0 && this.populateWith.constructor === Object)
    },
    title() {
      return this.isEdit ? 'Редактировать пользователя' : 'Добавить пользователя'
    },
    buttonLabel() {
      return this.isEdit ? 'Сохранить' : 'Добавить'
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    getUsers: debounce((value, self) => {
      self.form.gto.loading = true;
      api.gto.searchUser({search: value}).then((response) => {
        self.form.gto.list = response.data.list;
        self.form.gto.loading = false;
      })
    }, 500),
    getMunicipals: debounce((value, self) => {
      self.form.municipals.loading = true;
      api.kladr.get({query: value, contentType: 'city', regionId: self.form.regions.selected.id})
          .then((response) => {
            self.form.municipals.list = response.data.list;
            self.form.municipals.loading = false;
          })
    }, 500),
    removeMunicipality() {
      this.form.municipals.selected = null
      this.form.municipals.list = []
    },
    submit() {
      this.$refs.observer.validate().then(async (result) => {
        if (result) {
          this.loading = true;

          let data = {
            gto_profile: this.form.gto.selected,
            role: this.form.role,
            region: this.form.regions.selected,
            municipality: this.form.municipals.selected,
            user: this.form.user
          }

          try {
            if (this.isEdit) {
              await api.user.update(this.form.user.id, data);
            } else {
              await api.user.store(data);
            }

            this.loading = false;
            this.dialog = false;

            this.$emit('submit-success');

            this.notify('Пользователь был успешно ' + ((this.isEdit) ? 'изменен' : 'добавлен'))

          } catch (err) {
            console.error(err)
          }
        }
      }).catch(errorHandler);
    }

  }
}
</script>

<style scoped>

</style>