export const roles = {
    all: {
        name: 'all',
        title: 'Все роли',
    },
    admin: {
        name: 'admin',
        title: 'Администратор',
    },
    regional_operator: {
        name: 'regional_operator',
        title: 'Региональный оператор'
    },
    municipal_operator: {
        name: 'municipal_operator',
        title: 'Муниципальный оператор'
    },
    judge: {
        name: 'judge',
        title: 'Судья'
    }
}