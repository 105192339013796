<template>
  <div>
    <v-card v-if="!navigation">
      <v-card-text>
        <div class="row">
          <div class="col">
            <p class="subtitle-1 d-flex justify-space-between align-center">
              {{ processingStatusText }}
              <v-btn v-if="isSvodFailed" icon @click="cancel" :loading="form.loading"><v-icon>mdi-close</v-icon></v-btn>
            </p>
            <a :href="activeSvod.file.url">{{ activeSvod.file.original_name }}</a>
            <v-progress-linear :active="!isSvodFailed"
                               v-model="processingProgress"
            ></v-progress-linear>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column flex-sm-row align-start">
        <v-btn v-show="isSvodFailed" link color="primary" to="/svods/logs" class="mb-2 mb-sm-0">
          Посмотреть логи
        </v-btn>
        <v-btn
            color="primary"
            @click="reset()"
            v-show="isSvodFailed"
            class="ml-0 ml-sm-2"
        >
          Загрузить снова
        </v-btn>
        <v-btn v-show="!isSvodFailed"
               color="primary"
               outlined
               text
               @click="cancel()"
               :loading="form.loading"
               :disabled="form.loading"
        >
          Отменить
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="navigation && showInNavigation" class="pa-4">
      <p class="subtitle-1 d-flex justify-space-between align-center">
        {{ processingStatusText }}
        <v-btn v-if="isSvodFailed" icon @click="cancel" :loading="form.loading"><v-icon>mdi-close</v-icon></v-btn>
      </p>
      <v-progress-linear :active="!isSvodFailed && !isSvodProcessed"
                         v-model="processingProgress"
      ></v-progress-linear>
      <v-btn v-show="isSvodFailed" link color="primary" to="/svods/logs">
        Посмотреть логи
      </v-btn>
      <v-btn v-show="isSvodProcessed" link color="primary" to="/svods">
        Перейти
      </v-btn>
    </div>
  </div>
</template>

<script>

import {api} from "@/api";
import {mapGetters, mapState} from "vuex";

export default {
  name: "svod-progress-component",
  props: {
    navigation: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      loading: false,
    }
  }),
  computed: {
    ...mapState('svod', ['activeSvod']),
    ...mapGetters('svod', ['isSvodFailed', 'isSvodProcessed']),
    showInNavigation(){
      return this.$route.name !== 'svod.index';
    },
    processingProgress() {
      if (this.activeSvod) {
        if (this.activeSvod.status === 'uploaded') return 0;
        if (this.activeSvod.status === 'validating') return 10;
        if (this.activeSvod.status === 'processing') return 60;
        if (this.activeSvod.status === 'processed') return 100;
      }

      return 0;
    },
    processingStatusText() {
      if (this.activeSvod) {
        if (this.activeSvod.status === 'uploaded') return 'Файл загружен и ожидает проверки...';
        if (this.activeSvod.status === 'validating') return 'Идет проверка файла...';
        if (this.activeSvod.status === 'processing') return 'Идет обработка файла...';
        if (this.activeSvod.status === 'processed') return 'Новый СВОД загружен';
        if (this.activeSvod.status === 'failed') return 'Ошибка в загрузке нового СВОД';
      }

      return '';
    },
  },
  methods: {
    async cancel() {
      try {
        this.form.loading = true;
        await api.svod.cancel()
        this.$emit('canceled');
      } catch (e) {
        this.form.loading = false;
        console.error(e)
      }
    },
    reset() {
      this.$emit('reset');
    },
  }
}
</script>

<style scoped>

</style>