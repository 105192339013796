<template>
  <v-card
      class="mx-auto"
  >
    <v-form>
      <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
      >
    <v-card-text>
      <v-alert
          border="bottom"
          colored-border
          type="info"
          elevation="2"
      >Все новые и уже существующие фестивали без заполненных результатов будут рассчитываться по новой версии СВОД.
      </v-alert>
          <v-row>
            <v-col cols="12" class="col-md-5 col-sm-12">
              <ValidationProvider
                  name="Название"
                  rules="required"
                  v-slot="{ errors }"
                  mode="eager"
              >
                <v-file-input
                    v-model="form.file"
                    label="Выберите файл в формате .xlsx"
                    class="ma-0 pa-0"
                    hide-details
                    filled
                    dense
                    ref="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :error-messages="errors"
                    :disabled="form.loading"
                ></v-file-input>
              </ValidationProvider>
            </v-col>
          </v-row>
    </v-card-text>

          <v-card-actions>
            <v-btn
                color="primary"
                :disabled="invalid || form.loading"
                @click="submit"
                :loading="form.loading"
            >
              Загрузить
            </v-btn>
            <v-btn
                color="primary"
                outlined
                text
                @click="closeForm()"
            >
              Отменить
            </v-btn>
          </v-card-actions>

      </ValidationObserver>

      </v-form>

  </v-card>
</template>

<script>
import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';
import {api} from '@/api';

setInteractionMode('eager');

export default {
  name: "svod-uploader-component",
  components: {ValidationObserver, ValidationProvider},
  data: () => ({
    form: {
      loading: false,
      file: null
    }
  }),
  methods: {
    async submit() {
      try {
        this.form.loading = true;
        let formData = new FormData();
        formData.append('file', this.$refs.file.value)
        await api.svod.store(formData);
        this.$emit('uploaded');
      } catch (e) {
        console.error(e)
      }
    },
    closeForm(){
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>