import {guard} from '@/guards/guard';
import DeveloperIndex from "@/views/Developer/DeveloperIndex";
import Developer from "@/views/Developer/Developer";

export default {
    path: '/developer',
    component: Developer,
    meta: {title: 'Разработчику'},
    children: [
        {path: '', name: 'developer.index', beforeEnter: guard, component: DeveloperIndex, meta: {title: 'Разработчику'}},
    ]
}
