<template>
  <div>
    <v-data-table
        :headers="table.headers"
        :items="table.list"
        item-key="id"
        class="table"
        :loading="form.loading"
        :server-items-length="table.meta.total"
        ref="table"
        :footer-props="{
              itemsPerPageOptions : [100]
            }"
        hide-default-footer
        @click:row="saveItem"
    >
      <template v-slot:item.exercise_id="{ item }" class="pa-1">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >
        <v-autocomplete
            :items="item.exercises_list"
            v-model="item.exercise_id"
            outlined
            item-text="title_ru"
            item-value="id"
            placeholder="Выбрать"
            hide-details
            dense
            @change="changeSelect(item)"
            :error-messages="errors"
            :disabled="disabledInputs"
        ></v-autocomplete>
        </ValidationProvider>
        <v-alert
            v-if="item.notInCurrentVersion"
            border="bottom"
            colored-border
            class="mt-2"
            type="warning"
            elevation="2"
        >
          Этого испытания нет в используемом СВОДе
        </v-alert>
        <v-alert
            v-if="item.duplicate"
            border="bottom"
            colored-border
            class="mt-2"
            type="warning"
            elevation="2"
        >
          Дубликат испытания
        </v-alert>
      </template>
      <template v-slot:item.gender_id="{ item }">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >
        <v-select
            :items="item.genders_list"
            v-model="item.gender_ids"
            outlined
            item-text="name_ru"
            item-value="id"
            placeholder="Выбрать"
            dense
            hide-details
            multiple
            @change="changeSelect(item)"
            :error-messages="errors"
            :disabled="disabledInputs"
        >
          <template v-slot:prepend-item>
            <v-list-item
                ripple
                @click="selectAllGenders(item)"
            >
              <v-list-item-action>
                <v-icon v-if="checkedAllAvailableGenders(item)" color="primary">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Для всех
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
        </ValidationProvider>
      </template>
      <template v-slot:item.age_category_id="{ item }">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >
        <v-select
            :items="item.age_categories_list"
            v-model="item.age_category_ids"
            outlined
            item-text="description"
            item-value="id"
            placeholder="Выбрать"
            multiple
            dense
            hide-details
            :error-messages="errors"
            @change="changeSelect(item)"
            :disabled="disabledInputs"
        >
          <template v-slot:selection="props">
            <span v-if="props.index < 4">
             {{ props.item.name }}{{ (item.age_category_ids.length - 1 > props.index) ? ',' : '' }}
            </span>
            <span
                v-if="props.index === 4"
                class="grey--text caption"
            >
          + еще {{ item.age_category_ids.length - 1 }}
        </span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item
                ripple
                @click="selectAllAgeCategories(item)"
            >
              <v-list-item-action>
                <v-icon v-if="checkedAllAvailableCategories(item)" color="primary">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>

              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Для всех
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
        </ValidationProvider>
      </template>
      <template v-slot:item.priority="{ item }">
        <ValidationProvider
            rules="required|min_value:0"
            v-slot="{ errors }"
        >
        <v-text-field
            v-model="item.priority"
            dense
            hide-details
            type="number"
            :error-messages="errors"
            :disabled="disabledInputs"
            outlined
        >
        </v-text-field>
        </ValidationProvider>
      </template>
      <template v-slot:item.actions="{item}" v-if="!only_show">


        <div class="table-actions">
          <v-tooltip bottom content-class="tooltip-gto">
            <template #activator="{attrs, on}">
              <v-icon
                  v-if="canDelete()"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            Удалить
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <v-row class="pa-3" v-if="!only_show">
      <v-col cols="12">
        <v-btn color="primary" outlined text @click="addExercise" :disabled="form_loading">
          Добавить испытание
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog.show"
        persistent
        max-width="600"
        v-if="is_festival"
    >
      <v-card>
        <v-card-title class="headline">
          По испытанию есть заполненные протоколы
        </v-card-title>
        <v-card-text class="pb-0">Заполненые протоколы могут быть удалены</v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="confirmChanges()"
          >
            Продолжить
          </v-btn>
          <v-btn
              color="primary"
              text
              outlined
              @click="cancelChanges()"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ValidationProvider, setInteractionMode } from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('eager');

export default {
  name: 'templates-exercises-component',
  components: {ValidationProvider},
  props: {
    exercises: {
      type: Array,
      default: () => ([])
    },
    exercises_list: {
      type: Array,
      default: () => ([])
    },
    age_categories_list: {
      type: Array,
      default: () => ([])
    },
    genders_list: {
      type: Array,
      default: () => ([])
    },
    available_svod_exercises: {
      type: Array,
      default: () => ([])
    },
    form_loading: {
      type: Boolean,
      default: false
    },
    only_show: {
      type: Boolean,
      default: false
    },
    is_festival: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      table: {
        headers: [
          {
            text: 'Тип испытания',
            align: 'start',
            value: 'exercise_id',
            width: '50%',
          },
          {text: 'Пол', value: 'gender_id'},
          {text: 'Возрастная категория', value: 'age_category_id', sortable: false},
          {text: 'Приоритет', value: 'priority', sortable: false},
          {text: '', value: 'actions', sortable: false},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      exercisesList: this.exercises_list,
      gendersList: this.genders_list,
      ageCategoriesList: this.age_categories_list,
      dialog: {
        show: false,
        item: null,
        savedItem: null
      }
    }
  },
  created() {
    this.loadExercises()
  },
  watch: {
    exercises(){
      this.table.list = []
      this.loadExercises()
    }
  },
  computed: {
    disabledInputs() {
      return this.form_loading || this.only_show
    },
    hasDuplicates() {
      for (let i = 1; i < this.table.list.length; i++) {
        if (this.table.list[i].duplicate) return true;
      }

      return false;
    }
  },
  methods: {
    loadExercises(){
      if (this.exercises.length > 0){
        this.exercises.map((item) => {
          this.addExercise(item);
        })

        this.table.list.map(item => {
          this.filterSelects(item)
        })

      } else {
        this.addExercise(null);
      }
    },
    addExercise(data) {
      let exerciseList = JSON.parse(JSON.stringify(this.exercises_list));
      let exercise_id = (data && data.exercise) ? data.exercise.id : null;
      let notInCurrentVersion = false;

      //Тут мы смотрим есть ли испытание в текущей версии свода, если его нет предупрежаем пользвателя об этом
      //TODO возможно также надо узнать о полах и категориях
      if (!exerciseList.find(x => x.id === exercise_id) && (data && data.exercise)) {
        let exerciseData = data.exercise;
        exerciseData.disabled = true;
        exerciseList.push(exerciseData);
        notInCurrentVersion = true;
      }


      this.table.list.push({
        exercise_id: exercise_id,
        exercises_list: exerciseList,
        gender_ids: (data && data.genders && data.genders.list) ? data.genders.list.map((x) => x.id) : [],
        genders_list: JSON.parse(JSON.stringify(this.genders_list)),
        age_category_ids: (data && data.ageCategories && data.ageCategories.list) ? data.ageCategories.list.map((x) => x.id) : [],
        age_categories_list: JSON.parse(JSON.stringify(this.age_categories_list)),
        priority: (data && data.priority) ? data.priority : 0,
        id: (data && data.id) ? data.id : null,
        notInCurrentVersion: notInCurrentVersion,
        hasProtocols: (data && data.hasProtocols) ? data.hasProtocols : null
      })
    },
    exercisesFilter(item) {
      let availableExercises = []

      let exercises = this.thisGetNormalizedExercises();

      if (item.gender_ids.length > 0) {
        exercises = exercises.filter(exercise => {
          exercise.age_categories = exercise.age_categories.filter(x => {
            if (item.gender_ids.length === x.genders.length) {
              return JSON.stringify(item.gender_ids.sort()) === JSON.stringify(x.genders.sort());
            } else if (item.gender_ids.length < x.genders.length) {
              return x.genders.filter(y => item.gender_ids.includes(y))
            } else {
              return false
            }
          })
          return exercise.age_categories.length > 0;
        })
      }

      if (item.age_category_ids.length > 0) {
        exercises = exercises.filter(exercise => {
          let ageCategories = exercise.age_categories.map(x => x.id).filter(x => {
            return item.age_category_ids.includes(x)
          });

          return JSON.stringify(ageCategories.sort()) === JSON.stringify(item.age_category_ids.sort());
        })
      }


      availableExercises = exercises.map(exercise => exercise.id)

      item.exercises_list = item.exercises_list.map((exercise) => {
        exercise.disabled = !availableExercises.includes(exercise.id)
        return exercise;
      })

    },
    gendersFilter(item) {
      let availableGenders = []
      let exercises = this.thisGetNormalizedExercises();

      if (item.exercise_id) {
        exercises = exercises.filter(x => x.id === item.exercise_id);
      }


      if (item.age_category_ids.length > 0) {
        exercises = exercises.map(exercise => {
          exercise.age_categories = exercise.age_categories.filter((x) => {
            return item.age_category_ids.includes(x.id);
          });
          return exercise
        })
      }

      let temp = []
      exercises.map(exercise => {
        if (exercise.age_categories && exercise.age_categories.length > 0) {
          temp = exercise.age_categories[0].genders
          exercise.age_categories.map(x => {
            if (JSON.stringify(temp.sort()) === JSON.stringify(x.genders.sort())) {
              x.genders.map(y => {
                if (!availableGenders.includes(y)) {
                  availableGenders.push(y)
                }
              })
            } else if (item.age_category_ids.length > 0) {
              availableGenders = availableGenders.filter(y => x.genders.includes(y))
            }
          });
        }
      });

      item.genders_list = item.genders_list.map((gender) => {
        gender.disabled = !availableGenders.includes(gender.id)
        return gender;
      })
    },
    ageCategoriesFilter(item) {

      let availableCategories = []
      let exercises = this.thisGetNormalizedExercises();

      if (item.exercise_id) {
        exercises = exercises.filter(x => x.id === item.exercise_id);
      }

      if (item.gender_ids.length > 0) {
        exercises = exercises.map(exercise => {
          exercise.age_categories = exercise.age_categories.filter(x => {
            if (item.gender_ids.length === x.genders.length) {
              return JSON.stringify(item.gender_ids.sort()) === JSON.stringify(x.genders.sort());
            } else if (item.gender_ids.length < x.genders.length) {
              return x.genders.filter(y => item.gender_ids.includes(y))
            } else {
              return false
            }
          })

          return exercise;
        })

      }

      exercises.map(exercise => {
        if (exercise.age_categories) {
          exercise.age_categories.map(x => {
            if (!availableCategories.includes(x.id)) {
              availableCategories.push(x.id)
            }
          });
        }
      });

      item.age_categories_list = item.age_categories_list.map((category) => {
        category.disabled = !availableCategories.includes(category.id)
        return category;
      })
    },
    thisGetNormalizedExercises() {
      let exercises = [];
      exercises = this.exercisesList.map((exercise) => {

        let e = exercises.find(y => y.id === exercise.id);


        if (!e) {
          e = {
            'id': exercise.id,
            'age_categories': []
          }
        }

        e.age_categories = exercise.age_categories.map(x => {

          let c = e.age_categories.find(y => y.id === x.age_category_id);

          if (!c) {
            c = {
              'id': x.age_category_id,
              'genders': []
            }
          }

          x.genders.map(gender => {
            if (!c.genders.includes(gender.gender_id)) {
              c.genders.push(gender.gender_id);
            }
          });

          return c;
        });

        return e;
      });

      return exercises;
    },
    checkedAllAvailableCategories(item) {

      let categories = item.age_categories_list.filter((category) => {
        return !category.disabled
      })

      return categories.length === item.age_category_ids.length;
    },
    checkedAllAvailableGenders(item) {

      let genders = item.genders_list.filter((gender) => {
        return !gender.disabled
      })

      return item.gender_ids.length > 0 && genders.length === item.gender_ids.length;
    },
    selectAllAgeCategories(item) {
      if (this.checkedAllAvailableCategories(item)) {
        item.age_category_ids = []
      } else {
        item.age_category_ids = item.age_categories_list.filter((category) => {
          return !category.disabled
        }).map((category) => {
          return category.id
        })
      }

      this.filterSelects(item)
      this.changeSelect(item);
    },
    selectAllGenders(item) {
      if (this.checkedAllAvailableGenders(item)) {
        item.gender_ids = []
      } else {
        item.gender_ids = item.genders_list.filter((gender) => {
          return !gender.disabled
        }).map((gender) => {
          return gender.id
        })
      }
      this.filterSelects(item)
      this.changeSelect(item);
    },
    changeSelect(item) {
      //Check duplicates
      this.checkDuplicates(item);

      if (this.is_festival && item.hasProtocols && !item.changesConfirmed) {
        this.dialog.show = true;
        this.dialog.item = item;
        this.dialog.item.changed = true;
      }
      this.filterSelects(item);
    },
    checkDuplicates(item) {
      for (let l = 0; l <= this.table.list.length - 1; l++) {
        let i = this.table.list[l]
        //Чтобы строка не сравнивалась с собой
        if (!i.selected) {
          if (i.exercise_id === item.exercise_id && item.age_category_ids.length > 0 && item.gender_ids.length > 0) {
            this.$set(item, 'duplicate', false)

            for (let j = 0; j < item.gender_ids.length; j++) {
              for (let k = 0; k < item.age_category_ids.length; k++) {
                if (i.gender_ids.includes(item.gender_ids[j]) && i.age_category_ids.includes(item.age_category_ids[k])) {
                  this.$set(item, 'duplicate', true)
                  return;
                }
              }
            }


          } else {
            this.$set(item, 'duplicate', false)
          }
        }
      }
    },
    filterSelects(item) {
      this.gendersFilter(item)
      this.ageCategoriesFilter(item)
      this.exercisesFilter(item)
    },
    canDelete() {
      return (this.table.list.length > 1)
    },
    deleteItem(item) {
      if (this.is_festival && item.hasProtocols) {
        this.dialog.show = true;
        this.dialog.item = item;
        this.dialog.item.shouldBeDelete = true;
      } else {
        this.table.list.splice(this.table.list.indexOf(item), 1);
      }
    },
    confirmChanges() {
      if (this.dialog.item && this.dialog.item.shouldBeDelete) {
        this.table.list.splice(this.table.list.indexOf(this.dialog.item), 1);
      }

      if (this.dialog.item && this.dialog.item.changed) {
        this.dialog.item.changesConfirmed = true;
      }

      this.dialog.show = false;
    },
    cancelChanges() {
      this.dialog.show = false
      let idx = this.table.list.findIndex(x => x.id === this.dialog.item.id)
      this.table.list[idx] = JSON.parse(this.dialog.savedItem);
      this.table.list.splice()
    },
    //Это нужно что бы сохранить значение строки, если при вызови модалки с подтверждением изменений нажать отмену нам надо вернуть значение строки
    saveItem(value) {
      this.dialog.savedItem = JSON.stringify(value);
      //Помечаем выбранную строку
      this.table.list.forEach(i => {
        i.selected = false;
      });
      value.selected = true;
    }
  }
}
</script>

<style scoped>

</style>