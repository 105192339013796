var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Пользователь"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.gto.list,"search-input":_vm.userSearchString,"label":"Поиск по УИН или email","filled":"","loading":_vm.gto.loading,"item-value":"id","item-text":"name","no-filter":"","return-object":"","hide-details":"","error-messages":errors,"readonly":_vm.loading || _vm.isEdit,"dense":"","disabled":_vm.loading},on:{"update:searchInput":function($event){_vm.userSearchString=$event},"update:search-input":function($event){_vm.userSearchString=$event},"change":function($event){return _vm.setMemberFromGto()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.surname)+" "+_vm._s(data.item.middlename)+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.surname)+" "+_vm._s(data.item.middlename)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.id))])],1)]}}],null,true),model:{value:(_vm.gto.selected),callback:function ($$v) {_vm.$set(_vm.gto, "selected", $$v)},expression:"gto.selected"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v(" mdi-magnify ")])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2 mb-2 mb-md-0",attrs:{"color":"primary","filled":"","label":"Фамилия","hide-details":"","error-messages":errors,"disabled":_vm.loading,"dense":""},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2 mb-2 mb-md-0",attrs:{"color":"primary","filled":"","label":"Имя","hide-details":"","error-messages":errors,"disabled":_vm.loading,"dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2 mb-md-0",attrs:{"color":"primary","filled":"","label":"Отчество","hide-details":"","error-messages":errors,"disabled":_vm.loading,"dense":""},model:{value:(_vm.form.patronymic),callback:function ($$v) {_vm.$set(_vm.form, "patronymic", $$v)},expression:"form.patronymic"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mr-md-2 mb-2 mb-md-0",attrs:{"color":"primary","filled":"","label":"УИН","hide-details":"","error-messages":errors,"disabled":_vm.loading,"dense":""},model:{value:(_vm.form.uin),callback:function ($$v) {_vm.$set(_vm.form, "uin", $$v)},expression:"form.uin"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2 mb-md-0",attrs:{"color":"primary","filled":"","label":"Email","hide-details":"","error-messages":errors,"disabled":_vm.loading,"dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mr-md-2 mb-2 mb-md-0",attrs:{"items":_vm.countriesList,"label":"Страна","filled":"","item-value":"id","item-text":"name","return-object":"","hide-details":"","error-messages":errors,"dense":"","readonly":_vm.form.loading,"disabled":_vm.loading},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mr-md-2 mb-2 mb-md-0",attrs:{"items":_vm.regionsList,"label":"Регион","filled":"","item-value":"id","item-text":"fullname","return-object":"","hide-details":"","error-messages":errors,"dense":"","disabled":_vm.loading || _vm.canSetRegion},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mb-2 mb-md-0",attrs:{"items":_vm.teamsList,"label":"Команда","filled":"","item-value":"id","item-text":"teamable.name","return-object":"","hide-details":"","error-messages":errors,"dense":"","disabled":_vm.loading || !_vm.canSetTeams},model:{value:(_vm.form.team),callback:function ($$v) {_vm.$set(_vm.form, "team", $$v)},expression:"form.team"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0 pr-md-3 mb-3 mb-md-0",attrs:{"cols":"12","md":"4"}},[_c('div',{staticStyle:{"min-height":"115px","height":"100%"}},[_c('image-upload-component',{ref:"imageUploader",attrs:{"use-crop":true,"label":'Фотография',"disabled":_vm.loading,"file-data":_vm.form.photo,"file":_vm.form.photo},on:{"update:file":function($event){return _vm.$set(_vm.form, "photo", $event)}}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.genders.list,"filled":"","item-text":"text","item-value":"id","label":"Пол","hide-details":"","dense":"","return-object":"","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата рождения","prepend-inner-icon":"mdi-calendar","readonly":"","filled":"","dense":"","disabled":_vm.loading,"hide-details":""},model:{value:(_vm.birthdayFormatted),callback:function ($$v) {_vm.birthdayFormatted=$$v},expression:"birthdayFormatted"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"error-messages":errors,"max":_vm.maxDate,"min":_vm.minDate,"active-picker":"YEAR"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}})],1)]}}],null,true)})],1)],1)],1)],1),(_vm.populateWith && _vm.populateWith.age_category)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3"},[_vm._v("Назначенная возрастная группа: "+_vm._s(_vm.populateWith.age_category.description)+" ")])],1):_vm._e()],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || _vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"color":"primary","outlined":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Отменить ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }