import {roles} from '@/constants/roles';

const permissions = {
    list: ({$gates, user}) => {
        let list = {
            'users.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name].join('|')),
            'users.create': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name].join('|')),
            'users.edit.set.region': $gates.hasRole(roles.admin.name),
            'users.view.role.all': true,
            'users.view.role.admin': $gates.hasRole(roles.admin.name),
            'users.view.role.regional_operator': true,
            'users.view.role.municipal_operator': true,
            'users.view.role.judge': false,

            'exercises.index':  $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'exercises.create': $gates.hasRole(roles.admin.name),
            'exercises.edit': $gates.hasRole(roles.admin.name),
            'exercises.edit.visibility': $gates.hasRole(roles.admin.name),

            'svod.index': $gates.hasRole(roles.admin.name),
            'svod.logs.index': $gates.hasRole(roles.admin.name),

            'templates.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'templates.show': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'templates.edit': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'templates.create': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'templates.update': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),

            'festivals.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name, roles.judge.name].join('|')),
            'festivals.create': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'festivals.edit': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'festivals.exercises.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'festivals.teams.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'festivals.judges.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'festivals.members.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),
            'festivals.interface.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name].join('|')),

            'festivals.tournament-table.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name, roles.judge.name].join('|')),
            'festivals.screens.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name, roles.judge.name].join('|')),
            'festivals.protocols.index': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name, roles.judge.name].join('|')),
            'festivals.protocols.show': $gates.hasAnyRole([roles.admin.name, roles.regional_operator.name, roles.municipal_operator.name, roles.judge.name].join('|')),

            'developer.index': $gates.hasRole(roles.admin.name),

            'logs.index': $gates.hasRole(roles.admin.name),

            'analytics.index': user.access_analytics || $gates.hasRole(roles.admin.name),

        }

        return Object.keys(list).filter((key) => list[key])
    },
    actions: {
        editUser({$gates, user}, model){
            return (($gates.hasRole(roles.admin.name) && model.role.name !== roles.admin.name) ||
                ($gates.hasRole(roles.regional_operator.name) && model.role.name === roles.municipal_operator.name && model.region.id === user.region.id));
        },
        deleteUser({$gates, user},  model){
            return (($gates.hasRole(roles.admin.name)  && model.role.name !== roles.admin.name) ||
                ($gates.hasRole(roles.regional_operator.name) && model.role.name === roles.municipal_operator.name)
                && model.region.id === user.region.id);
        },
        deleteSvod({$gates},  model){
            let startedDate = new Date(model.started_at.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3"))
            let today = new Date();
            return (($gates.hasRole(roles.admin.name) && startedDate > today));
        },
        showTemplate({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.municipality.id === user.municipality.id);
        },
        editTemplate({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.municipality.id === user.municipality.id);
        },
        deleteTemplate({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.municipality.id === user.municipality.id);
        },
        showFestival({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.location.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.location.municipality.id === user.municipality.id) ||
                ($gates.hasRole(roles.judge.name));
        },
        archiveFestival({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.location.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.location.municipality.id === user.municipality.id);
        },
        editFestival({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.location.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.location.municipality.id === user.municipality.id);
        },
        deleteFestival({$gates, user}, model){
            return ($gates.hasRole(roles.admin.name)) ||
                ($gates.hasRole(roles.regional_operator.name) && model.location.region.id === user.region.id) ||
                ($gates.hasRole(roles.municipal_operator.name) && model.location.municipality.id === user.municipality.id);
        }
    }
}


export default permissions;