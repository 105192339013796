<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="720px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card-title>
            Добавить судей
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider
                    name="Пользователь"
                    rules="required"
                    v-slot="{ errors }"
                >

                  <v-autocomplete
                      v-model="form.judges"
                      :items="gto.list"
                      :search-input.sync="userSearchString"
                      label="Поиск по УИН или email"
                      placeholder="Начните писать текст"
                      filled
                      :loading="gto.loading"
                      item-value="id"
                      item-text="name"
                      no-filter
                      return-object
                      hide-details
                      dense
                      multiple
                      chips
                      deletable-chips
                      :error-messages="errors"
                      :readonly="loading"
                      @change="userSearchString = ''"
                  >
                    <v-icon
                        slot="prepend-inner"
                    >
                      mdi-magnify
                    </v-icon>


                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                          class="mt-1 ml-0 mr-1 mb-1"
                          small
                      >
                        {{ `${data.item.surname} ${data.item.name} ${data.item.middlename}` }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }} {{ data.item.surname }} {{
                            data.item.middlename
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.id }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.item.already_in_current_festival">Уже в списке судей этого фестиваля</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.item.busy_on_festival">
                          <strong>Добавление невозможно.</strong>
                          <p class="pb-0 mb-0">Данный пользователь уже назначен в роли судьи на другой фестиваль:</p>
                          <p class="pb-0 mb-0">ID {{ data.item.busy_on_festival.id }} - {{
                              data.item.busy_on_festival.title_ru ? data.item.busy_on_festival.title_ru : data.item.busy_on_festival.title_en
                            }}</p>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

          </v-card-text>


          <v-card-actions class="pa-4">
            <v-btn
                color="primary"
                :disabled="invalid || loading"
                @click="submit()"
                :loading="loading"
            >
              Добавить
            </v-btn>

            <v-btn
                color="primary"
                outlined
                text
                @click="dialog = false"
                v-show="!loading"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');
import debounce from 'debounce'
import {mapState,mapActions} from 'vuex';

import {api} from '@/api';

export default {
  name: 'create-judges-component-user',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    dialog: false,
    loading: false,
    userSearchString: '',
    regionSearchString: '',
    municipalSearchString: '',
    form: {
      judges: []
    },
    gto: {
      list: [],
      selected: null,
      loading: false
    },
  }),
  watch: {
    userSearchString(value) {
      if (value) this.getUsers(value, this);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify']),
    getUsers: debounce((value, self) => {
      self.gto.loading = true;
      api.festival.judges.search(self.$route.params.id, {search: value}).then((response) => {
        self.gto.list = [...response.data.list, ...self.form.judges];
        self.gto.loading = false;
      })
    }, 500),
    async submit(){
      this.loading = true;
      try {
        await api.festival.judges.store(this.$route.params.id, {judges: this.form.judges});
        this.form.judges = [];
        this.$emit('submitted');
        this.dialog = false;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    remove (item) {
      const index = this.form.judges.findIndex(x => x.id === item.id)
      if (index >= 0) this.form.judges.splice(index, 1)
    },
  }
}
</script>

<style scoped>

</style>