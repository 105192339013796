<template>
  <div>
    <festival-tabs-component :festival="festival"></festival-tabs-component>
    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
    >
      <div v-show="page.loading" class="text-center pa-10">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </div>

    <v-form v-show="!page.loading">
      <div v-if="festival">
        <festival-about-form-component ref="aboutForm" :festival="festival" :form-data="festival"></festival-about-form-component>
        <v-divider class="mt-8"></v-divider>
        <festival-location-form-component ref="locationForm" :regions-data="regions" :festival="festival"
                                          :form-data="festival.location"></festival-location-form-component>
        <v-divider class="mt-8"></v-divider>
        <festival-settings-form-component ref="settingsForm" :festival="festival" :form-data="festival.settings"></festival-settings-form-component>
      </div>
      <div class="sticky-actions">
        <v-divider></v-divider>
        <v-row class="pa-3">
          <v-col cols="12">
            <v-btn color="primary" :disabled="invalid || form.loading" @click="submit" :loading="form.loading">
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
    </ValidationObserver>

    <leave-form :title="'Внесенные изменения в фестиваль не сохранены.'" ref="leaveModal"
                @goto="dontSaveAndGoTo"></leave-form>

    <v-dialog
        v-model="removeTeamsDialog.show"
        persistent
        max-width="720"
    >
      <v-card>
        <v-card-title>
          Фестиваль уже содержит команды категории {{ getCategoryName }}
        </v-card-title>
        <v-card-text>
          При изменении категории команды, все текущие команды фестиваля будут удалены. Участники будут откреплены от команд. Продолжить?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              text
              @click="confirmChangeCategory()"
          >
            Да
          </v-btn>
          <v-btn
              color="primary"
              @click="removeTeamsDialog.show = false"
          >
            Нет, остаться
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import { ValidationObserver, setInteractionMode } from 'vee-validate/dist/vee-validate.full.esm';
setInteractionMode('eager');

import FestivalTabsComponent from "@/views/Festival/components/festival-tabs-component";
import FestivalAboutFormComponent from "@/views/Festival/components/forms/festival-about-form-component";
import {api} from "@/api";
import FestivalLocationFormComponent from "@/views/Festival/components/forms/festival-location-form-component";
import FestivalSettingsFormComponent from "@/views/Festival/components/forms/festival-settings-form-component";
import LeaveForm from "@/components/modals/leave-form";

export default {
  name: 'FestivalEdit',
  components: {
    LeaveForm,
    FestivalSettingsFormComponent,
    FestivalLocationFormComponent, FestivalAboutFormComponent, FestivalTabsComponent, ValidationObserver},
  data() {
    return {
      festival: null,
      regions: [],
      form: {
        loading: false,
      },
      page: {
        loading: true
      },
      formState: null,
      dialog: false,
      goTo: null,
      dontSaveAndGo: false,
      removeTeamsDialog: {
        show: false,
        confirm: false
      }
    }
  },
  mounted() {
    this.fetch();
  },
  updated() {
    this.setFormState();
    this.page.loading = false;
  },
  computed: {
    ...mapState('auth', ['user']),
    getCategoryName(){
      if (this.festival) {
        let categories = {
          country: 'Страна',
          region: 'Регион',
          municipality: 'Район',
          corporate: 'Корпоратив',
          school: 'Школа',
          other: 'Другое'
        }

        return categories[this.festival.settings.team_category]
      }

      return null;
    }
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),
    ...mapActions('festival', ['set_festival']),
    async fetch() {
      try {
        let response = await api.festival.get(this.$route.params.id);
        response.data.festival.languages = response.data.festival.languages.map(x => x.short_name)
        this.festival = response.data.festival;
        this.regions = response.data.regions;
        this.set_festival(this.festival);

      } catch (e) {
        console.error(e);
      }
    },
    async submit(){
      try {

        let data = JSON.parse(JSON.stringify({
          ...this.$refs.aboutForm.form,
          location: this.$refs.locationForm.form,
          settings: this.$refs.settingsForm.form
        }))

        //Check team_category changes
        console.log(this.festival.settings.team_category, data.settings.team_category)
        if (this.festival.has_teams && this.festival.settings.team_category !== data.settings.team_category && !this.removeTeamsDialog.confirm){
            this.removeTeamsDialog.show = true;
            return;
        }

        this.form.loading = true;

        data.settings.badge_6x9_header_color = this.colors(data.settings.badge_6x9_header_color)
        data.settings.badge_6x9_text_color = this.colors(data.settings.badge_6x9_text_color)
        data.settings.badge_a6_text_color = this.colors(data.settings.badge_a6_text_color)
        data.settings.badge_a6_header_color = this.colors(data.settings.badge_a6_header_color)

        let response = await api.festival.update(this.$route.params.id, data)
        this.festival = response.data;
        this.set_festival(this.festival);
        this.setFormState();


        this.removeTeamsDialog.confirm = false;
        this.notify('Изменения сохранены')
      } catch (e) {
        this.error(e)
      }
      this.form.loading = false
    },
    confirmChangeCategory(){
      this.removeTeamsDialog.confirm = true;
      this.removeTeamsDialog.show = false;
      this.submit();
    },
    colors(data){
      if (typeof data === 'object'){
        return  data.hexa;
      }
      return data;
    },
    setFormState(){
      let data = JSON.parse(JSON.stringify({
        ...this.$refs.aboutForm.form,
        location: this.$refs.locationForm.form,
        settings: this.$refs.settingsForm.form
      }))

      data.settings.badge_6x9_header_color = this.colors(data.settings.badge_6x9_header_color)
      data.settings.badge_6x9_text_color = this.colors(data.settings.badge_6x9_text_color)
      data.settings.badge_a6_text_color = this.colors(data.settings.badge_a6_text_color)
      data.settings.badge_a6_header_color = this.colors(data.settings.badge_a6_header_color)

      this.formState = JSON.stringify(data)
    },
    dontSaveAndGoTo(){
      this.dontSaveAndGo = true;
      this.$router.push(this.goTo)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.dontSaveAndGo) {

      let data = JSON.parse(JSON.stringify({
        ...this.$refs.aboutForm.form,
        location: this.$refs.locationForm.form,
        settings: this.$refs.settingsForm.form
      }))

      data.settings.badge_6x9_header_color = this.colors(data.settings.badge_6x9_header_color)
      data.settings.badge_6x9_text_color = this.colors(data.settings.badge_6x9_text_color)
      data.settings.badge_a6_text_color = this.colors(data.settings.badge_a6_text_color)
      data.settings.badge_a6_header_color = this.colors(data.settings.badge_a6_header_color)

      let currentState = JSON.stringify(data);

      if (currentState !== this.formState) {
        this.$refs.leaveModal.dialog = true
        this.goTo = to
        return;
      }
    }
    next();
  },

}
</script>

<style scoped>

</style>
