<template>
  <div>
    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="4" class="mb-6 mb-md-0">
        <v-btn
            color="primary"
            @click="showCreateUserModal"
            v-permission="'users.create'"
        >
          Добавить пользователя
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск УИН, email или фамилии"
            dense
            @keyup="searchUsers"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
            v-model="form.role.selected"
            :items="rolesList"
            item-text="title"
            item-value="name"
            persistent-hint
            single-lins
            placeholder="Все роли"
            filled
            dense
            @change="changeRole"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.region="{ item }">
            {{ item.role.name === roles.admin.name ? 'Все' : item.region.fullname }}
          </template>
          <template v-slot:item.municipality="{ item }">
            {{ item.role.name === roles.admin.name ? 'Все' : (item.role.name === roles.regional_operator.name) ? '—' : item.municipality.name }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="table-actions d-flex">
            <v-tooltip left content-class="tooltip-gto">
              <template #activator="{attrs, on}">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-if="canEdit(item)"
                    @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              Редактировать
            </v-tooltip>
            <v-tooltip left content-class="tooltip-gto">
              <template #activator="{on, attrs}">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    v-if="canDelete(item)"
                    @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Удалить
            </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <create-edit-user ref="createEditUserModal" :populate-with="selectedUser" :regions-data="regions"
                      @submit-success="userSubmitted"></create-edit-user>
    <remove-item ref="removeUser" :populate-with="selectedUser" :type="'user'" @remove-item-success="userRemoved"></remove-item>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapGetters, mapActions} from 'vuex';
import {api} from '@/api';
import CreateEditUser from '@/views/User/components/create-edit-user'
import RemoveItem from '@/components/modals/remove-item';
import permissions from '@/constants/permisions';

export default {
  name: 'UserIndex',
  components: { RemoveItem, CreateEditUser},
  data() {
    return {
      regions: [],
      form: {
        search: null,
        loading: false,
        role: {
          selected: 'all'
        }
      },
      table: {
        headers: [
          {
            text: 'УИН',
            align: 'start',
            value: 'profile.id',
          },
          { text: 'ФИО', value: 'profile.fullname' },
          { text: 'E-mail', value: 'email', sortable: false },
          { text: 'Роль', value: 'role.title', sortable: false },
          { text: 'Регион', value: 'region', sortable: false },
          { text: 'Муниципалитет', value: 'municipality', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selectedUser: null
    }
  },
  computed: {
    ...mapGetters('roles', [ 'filteredRoles']),
    ...mapState('roles', [ 'roles']),
    ...mapState('auth', ['user']),
    rolesList(){
      return this.filteredRoles(this)
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    showCreateUserModal(){
      this.selectedUser = {};
      this.openModal();
    },
    async fetchUsers(){
      this.form.loading = true;
      try {
        let response = await api.user.all({
          search: this.form.search,
          role: this.form.role.selected,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.users.list
        this.table.meta = response.data.users.meta
        this.regions = response.data.regions;
        this.form.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
    editItem(item){
      this.selectedUser = item;
      this.openModal();
    },
    deleteItem(item){
      this.selectedUser = item;
      this.$refs.removeUser.dialog = true;
    },
    openModal(){
      this.$refs.createEditUserModal.dialog = true;
    },
    canEdit(item){
      return permissions.actions.editUser(this, item);
    },
    canDelete(item){
      return permissions.actions.deleteUser(this, item);
    },
    userSubmitted(){
      this.fetchUsers();
    },
    userRemoved(){
      this.fetchUsers();
      this.notify('Пользователь был отключен')
    },
    optionsUpdated(){
      this.fetchUsers()
    },
    changeRole(){
      this.table.options.page = 1;
      this.fetchUsers();
    },
    searchUsers: debounce(function (){
      this.table.options.page = 1;
     this.fetchUsers()
    }, 500)
  },
}
</script>

<style scoped>

</style>
