<template>
  <div>
    <festival-view-tabs-component></festival-view-tabs-component>
    <p class="headline pa-3 mb-0" v-if="exercise">{{ exercise.title_ru || exercise.title_en }}</p>

    <v-row no-gutters class="pa-3 pb-0">
      <v-col cols="12" md="6" class="mb-3 mb-md-0">
        <v-btn color="primary" class="mr-3" outlined text
               @click="importDialog.show = true">Импорт протокола
        </v-btn>

        <v-btn color="primary" :loading="template.loading" :disabled="template.loading" outlined text
               @click="downloadTemplate()">Скачать шаблон
        </v-btn>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
            color="primary"
            v-model="form.search"
            class="mr-md-2"
            filled
            search
            placeholder="Поиск по УИН, ФИО"
            dense
            @keyup="search"
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
          <template v-slot:item.value="{ item }">
            <v-text-field
                v-model="item.value"
                filled
                dense
                :loading="item.loading"
                :disabled="item.loading"
                @blur="setValue(item, $event)"
                :placeholder="placeHolder"
                v-mask="inputMask"
                hide-details
            ></v-text-field>
          </template>
          <template v-if="devMode" v-slot:item.points="{ item }">
            <v-text-field
                v-model="item.points"
                filled
                dense
                :loading="item.loading"
                :disabled="item.loading"
                @blur="setPoints(item, $event)"
                hide-details
            ></v-text-field>
            <v-alert type="warning">Это поле доступно для редактирования, потому что включен режим разработчика
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
        v-model="importDialog.show"
        persistent
        max-width="480"
    >
      <v-form>
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card>
            <v-card-title class="text-h5">
              Импортировать протокол
            </v-card-title>
            <v-card-text>Загрузка результатов из таблицы</v-card-text>
            <v-card-text>
                <ValidationProvider
                    name="Название"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-file-input
                      v-model="form.file"
                      label="Выберите файл в формате .xlsx"
                      class="ma-0 pa-0"
                      hide-details
                      fille
                      dense
                      filled
                      ref="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      :error-messages="errors"
                      :disabled="importDialog.loading"
                  ></v-file-input>
                </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  outlined
                  text
                  @click="importDialog.show = false"
                  v-show="!importDialog.loading"
              >
                Отменить
              </v-btn>
              <v-btn
                  color="primary"
                  :disabled="invalid || importDialog.loading"
                  :loading="importDialog.loading"
                  @click="importTemplate()"
              >
                Импортировать
              </v-btn>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-form>
    </v-dialog>

  </div>
</template>
<script>
import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';
import debounce from 'debounce'

setInteractionMode('eager');

import {mapState, mapActions, mapGetters} from 'vuex';
import {api} from '@/api';
import FestivalViewTabsComponent from '@/views/Festival/components/festival-view-tabs-component';

export default {
  name: 'FestivalProtocolIndex',
  components: {
    FestivalViewTabsComponent,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      form: {
        search: null,
        onlyMine: false,
        loading: false,
        role: {
          selected: 'all'
        }
      },
      table: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            value: 'member.id',
            width: '7%'
          },
          {text: 'УИН', value: 'member.uin'},
          {text: 'ФИО', value: 'member.fullname'},
          {text: 'Результат', value: 'value', width: '15%'},
          {text: 'Балл', value: 'points', width: '10%'},
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
      selected: null,
      exercise: null,
      festival: null,
      template: {
        loading: false
      },
      importDialog: {
        show: false,
        loading: false,
        batch_id: null,
        interval: null
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('app', ['devMode']),
    inputMask() {
      switch (this.exercise.unit.name) {
        case 'short_distance':
        case 'meters':
          return '##.##';
        case 'long_distance':
          return '##:##.##';
        case 'number_of':
        case 'number_of_points':
        case 'centimeters':
          return [/\d/, /\d/, /\d/, /\d/]
        case 'negative_value':
          return (value) => {
            if (value.charAt(0) === '-') {
              return ['-', /\d/, /\d/]
            } else {
              return [/\d/, /\d/]
            }
          }
      }
      return null
    },
    placeHolder() {
      switch (this.exercise.unit.name) {
        case 'short_distance':
        case 'meters':
          return '00.00'
        case 'long_distance':
          return '00:00.00';
        case 'number_of':
        case 'number_of_points':
        case 'centimeters':
          return '0'
        case 'negative_value':
          return '±0'
      }
      return null
    }
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),

    async fetch() {

      try {
        this.form.loading = true;
        let response = await api.festival.protocols.get(this.$route.params.id, this.$route.params.exercise, {
          search: this.form.search,
          only_mine: this.form.onlyMine,
          role: this.form.role.selected,
          sortBy: this.table.options.sortBy[0] || null,
          sortDesc: this.table.options.sortDesc[0] || null,
          limit: this.table.options.itemsPerPage,
          page: this.table.options.page
        });

        this.table.list = response.data.list;
        this.table.meta = response.data.meta;
        this.exercise = response.data.exercise;
        this.festival = response.data.festival;
        this.form.loading = false;
      } catch (e) {
        console.error(e);
      }
    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated() {
      this.fetch()
    },
    async setValue(item, e) {
      try {
        item.value = this.convertValue(item.value);

        //Проблема в том что после маски с отризательным значением, потому что там маска как функция, не работает реактивный инпут
        //Поэтому берем элемент и пихаем туда значение напрямую
        if (this.exercise.unit.name === 'negative_value') {
          item.value = parseInt(item.value)
          e.target.value = item.value
        }

        console.log(e.target.value, item.value)


        this.$set(item, 'loading', true)
        let response = await api.festival.protocols.update(this.$route.params.id, this.$route.params.exercise, item.id, {value: item.value});
        item.loading = false;
        item.points = response.data.points;

      } catch (e) {
        console.error(e)
      }
    },
    async setPoints(item) {
      await api.festival.protocols.update(this.$route.params.id, this.$route.params.exercise, item.id, {
        value: item.value,
        points: item.points
      });
    },
    convertValue(value) {
      if (value.length === 0) {
        return null;
      }

      if (['short_distance', 'meters'].includes(this.exercise.unit.name)) {
        let values = ['00', '00'];
        let split = value.split('.');
        (split[0] && split[0].length === 1) ? values[0] = `0${split[0]}` : values[0] = split[0];
        if (split[1] && split[1].length === 1) {
          values[1] = `${split[1]}0`
        } else if (split[1] && split[1].length === 2) {
          values[1] = split[1];
        }
        return values.join('.');
      } else if (this.exercise.unit.name === 'long_distance') {
        let values = ['00', '00', '00'];
        let split = value.split(/:|\./);
        (split[0] && split[0].length === 1) ? values[0] = `0${split[0]}` : values[0] = split[0];

        if (split[1] && split[1].length === 1) {
          values[1] = `0${split[1]}`
        } else if (split[1] && split[1].length === 2) {
          values[1] = split[1];
        }

        if (split[2] && split[2].length === 1) {
          values[2] = `${split[2]}0`;
        } else if (split[2] && split[2].length === 2) {
          values[2] = split[2];
        }

        return `${values[0]}:${values[1]}.${values[[2]]}`;
      } else if (['number_of', 'number_of_points', 'centimeters'].includes(this.exercise.unit.name)) {
        return value;
      } else if (['negative_value'].includes(this.exercise.unit.name)) {
        let values = ['00', '0'];
        let negative = false;

        if (value.charAt(0) === '-') {
          negative = true;
          value = value.replace('-', '');
        }

        let split = value.split('.');
        (split[0] && split[0].length === 1) ? values[0] = `0${split[0]}` : values[0] = split[0];
        if (split[1] && split[1].length === 1) {
          values[1] = split[1]
        }

        if (negative) {
          values[0] = `-${values[0]}`;
        }

        return values.join('.');
      }

      return null;
    },
    async downloadTemplate() {
      this.template.loading = true;
      try {
        let response = await api.festival.protocols.template.download(this.$route.params.id, this.$route.params.exercise)
        this.download(response.data.template_url, 'template.xlsx')
      } catch (e) {
        console.error(e);
      }
      this.template.loading = false
    },
    async importTemplate() {
      this.importDialog.loading = true;
      try {
        let formData = new FormData();
        formData.append('file', this.$refs.file.value);
        const res = await api.festival.protocols.template.upload(this.$route.params.id, this.$route.params.exercise, formData)
        this.importDialog.batch_id = res.data.batch_id
        this.importDialog.interval = setInterval(() => {
          this.importTemplateCheckStatus()
        }, 5000);
      } catch (e) {
        this.error('Произошла ошибка при загрузке файла.');
      }
    },
    async importTemplateCheckStatus() {
      try {
        const res = await api.festival.protocols.template.status(this.$route.params.id, this.$route.params.exercise, {batch_id: this.importDialog.batch_id});
        if (res.data.status === 'completed') {
          this.importDialog.loading = false;
          this.importDialog.show = false;
          clearInterval(this.importDialog.interval);
          this.notify('Файл успешно загружен');
          await this.fetch();
        }

        if (res.data.status === 'error') {
          this.importDialog.loading = false;
          this.importDialog.show = false;
          clearInterval(this.importDialog.interval);
          this.error('Произошла ошибка при обработке файла.' + res.data.messages.join(' '));
        }

      } catch (e) {
        this.importDialog.loading = false;
        this.importDialog.show = false;
        this.error('Произошла ошибка при загрузке файла.')
        clearInterval(this.importDialog.interval);
      }
    },
    download(dataurl, filename) {
      let a = document.createElement('a');
      a.href = dataurl;
      a.setAttribute('download', filename);
      a.click();
    }
  },
}
</script>

<style scoped>

</style>
