<template>
  <div>
    <festival-view-tabs-component></festival-view-tabs-component>
    <v-row class="pa-3">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" lg="12" md="12" class="pb-md-0 mb-md-0">
            <v-select
                :items="creditTypes"
                v-model="form.creditType"
                item-value="id"
                item-text="text"
                filled
                dense
                :disabled="form.loading"
                label="Тип зачета"
                @change="fetch"
                hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" lg="12" md="12" class="pb-md-0 mb-md-0">
            <v-select
                :items="teams"
                v-model="form.teams"
                item-value="id"
                item-text="text"
                filled
                dense
                multiple
                :disabled="form.loading || form.creditType === 'personal'"
                label="Команда"
                @change="fetch"
                hide-details
            >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggleSelector('teams')"
                >
                  <v-list-item-action>
                    <v-icon v-if="form.teams.length === teams.length" color="primary">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else>
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Все команды
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:selection="{ item, index }">
                <span v-if="form.teams.length === teams.length && index < 1">Все команды</span>
                <span v-else-if="form.teams.length !== teams.length">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption"> + еще {{ form.teams.length - 1 }}</span>
                </span>
              </template>

            </v-select>
          </v-col>
          <v-col cols="12" lg="12" md="12" class="pb-md-0 mb-md-0">
            <v-select
                :items="ratings"
                v-model="form.rating"
                item-value="id"
                item-text="text"
                filled
                dense
                :disabled="form.loading"
                label="Рейтинг"
                @change="fetch"
                hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" lg="12" md="12" class="pb-md-0 mb-md-0">
            <v-select
                :items="exercises"
                v-model="form.exercises"
                item-value="id"
                item-text="text"
                filled
                dense
                :disabled="form.loading"
                label="Испытание"
                multiple
                @input="fetch"
                hide-details
            >

              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggleSelector('exercises')"
                >
                  <v-list-item-action>
                    <v-icon v-if="form.exercises.length === exercises.length" color="primary">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else>
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Все испытания
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:selection="{ item, index }">
                <span v-if="form.exercises.length === exercises.length && index < 1">Все испытания</span>
                <span v-else-if="form.exercises.length !== exercises.length">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption"> + еще {{ form.exercises.length - 1 }}</span>
                </span>
              </template>

            </v-select>
          </v-col>
          <v-col cols="12" lg="12" md="12" class="pb-md-0 mb-md-0">
            <v-select
                :items="genders"
                v-model="form.genders"
                item-value="id"
                item-text="text"
                filled
                dense
                label="Пол"
                multiple
                :disabled="form.loading"
                @change="fetch"
                hide-details
            >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggleSelector('genders')"
                >
                  <v-list-item-action>
                    <v-icon v-if="form.genders.length === genders.length" color="primary">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else>
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Все
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

            </v-select>
          </v-col>
          <v-col cols="12" lg="12" md="12" class="pb-md-0 mb-md-0">
            <v-select
                :items="age_categories"
                v-model="form.age_categories"
                item-value="id"
                item-text="text"
                filled
                dense
                :disabled="form.loading"
                multiple
                label="Возр. категория"
                @change="fetch"
                hide-details
            >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggleSelector('age_categories')"
                >
                  <v-list-item-action>
                    <v-icon v-if="form.age_categories.length === age_categories.length" color="primary">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else>
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Все категории
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:selection="{ item, index }">
                <span v-if="form.age_categories.length === age_categories.length && index < 1">Все категории</span>
                <span v-else-if="form.age_categories.length !== age_categories.length">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption"> + еще {{ form.age_categories.length - 1 }}</span>
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-3 pt-0">
      <v-col cols="12" md="6">
        <v-text-field
            color="primary"
            v-model="form.search"
            filled
            search
            placeholder="Поиск по ФИО, УИН"
            dense
            :disabled="form.loading"
            @keyup="search"
            hide-details
        >
          <v-icon
              slot="prepend-inner"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-sm-space-between justify-md-end">
        <v-btn color="primary" class="mr-3" :loading="exportTo.loading" :disabled="exportTo.loading" @click="exportToXlsx()">Экспорт xlsx</v-btn>
        <v-btn color="primary" outlined @click="clearFilters">Сбросить фильтр</v-btn>
      </v-col>

    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
            :headers="table.headers"
            :items="table.list"
            item-key="id"
            class="table"
            :loading="form.loading"
            :server-items-length="table.meta.total"
            ref="table"
            :options.sync="table.options"
            :footer-props="{
              itemsPerPageOptions : [10,25,50,-1]
            }"
            @update:options="optionsUpdated"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import debounce from 'debounce'

import {mapState, mapActions} from 'vuex';
import {api} from '@/api';
import FestivalViewTabsComponent from "@/views/Festival/components/festival-view-tabs-component";

export default {
  name: 'FestivalTournamentTable',
  components: {
    FestivalViewTabsComponent,
  },
  data() {
    return {
      page: {
        loading: false,
      },
      festival: null,
      form: {
        search: null,
        loading: false,
        creditType: 'personal',
        rating: 'summary',
        teams: [],
        exercises: [],
        genders: [1,2],
        age_categories: []
      },
      exportTo: {
        loading: false
      },
      creditTypes: [
        {id: 'personal', text: 'Личный'},
        {id: 'team', text: 'Командный'}
      ],
      ratings: [
        {id: 'summary', text: 'Суммарный'},
        {id: 'average', text: 'Средний'},
      ],
      genders: [
        {id: 1, text: 'Мужской'},
        {id: 2, text: 'Женский'}
      ],
      teams: [],
      exercises: [],
      age_categories: [],
      table: {
        headers: [
        ],
        list: [],
        meta: {
          total: -1
        },
        options: null
      },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('app', ['notify']),
    async fetch() {
      this.form.loading = true;

      this.setupTable();
      try {
        let response = await api.festival.tournamentTable.get(this.$route.params.id, {
          ...this.form, ...{
            search: this.form.search,
            sortBy: this.table.options.sortBy[0] || null,
            sortDesc: this.table.options.sortDesc[0] || null,
            limit: this.table.options.itemsPerPage,
            page: this.table.options.page
          }
        });
        if (!this.festival) {
          this.festival = response.data.festival;

          this.teams = this.festival.teams.list.map(team => {
            return {id: team.id, text: team.teamable.name}
          })

          let exercises = new Map();
          this.festival.exercises.map(exercise => {
            exercises.set(exercise.exercise.id, {id: exercise.exercise.id, text: exercise.exercise.title_ru})
          })

          this.exercises = Array.from(exercises.values());

          let ageCategories = new Map();
          this.festival.exercises.map(exercise => {
            exercise.ageCategories.list.map(x => {
              ageCategories.set(x.id, {id: x.id, text: x.name})
            })
          });

          this.age_categories =  Array.from(ageCategories.values()).sort((a, b) => {
            if (a.id < b.id) return -1
            return a.id > b.id ? 1 : 0
          });

          this.defaultFilters();
        }
        this.table.list = response.data.list;
        this.table.meta = response.data.meta;

      } catch (e) {
        console.error(e);
      }
      this.form.loading = false;
    },
    defaultFilters(){
      this.form = {
        search: null,
        loading: false,
        creditType: 'personal',
        rating: 'summary',
        teams: [],
        exercises: [],
        genders: [1,2],
        age_categories: []
      }


      this.exercises.map(x => this.form.exercises.push(x.id));
      this.age_categories.map(x => { this.form.age_categories.push(x.id)});
      this.teams.map(x => { this.form.teams.push(x.id)});
    },
    clearFilters(){
      this.defaultFilters();
      this.fetch();
    },
    setupTable() {
      let headers = [
        {
          text: 'Место',
          align: 'start',
          value: 'place',
        },
      ];

      if (this.form.creditType === 'personal') {
        headers.push({text: 'УИН', value: 'member.uin', sortable: false});
        headers.push({text: 'ФИО', value: 'member.fullname', sortable: false});
        headers.push({text: 'Пол', value: 'member.gender.name_ru', sortable: false});
        headers.push({text: 'Возрастная ступень', value: 'member.age_category.name', sortable: false, width: '10%'});
      } else {
        headers.push({text: 'Команда', value: 'team.teamable.name', sortable: false});
      }

      if (this.form.exercises.length > 0 && this.form.exercises.length < this.exercises.length) {
        this.form.exercises.forEach(id => {
          let exercise = this.exercises.find(x => x.id === id);
          headers.push({text: `${exercise.text} (Баллы)`, value: `exercises.${id}.points`, sortable: false});

          if (this.form.creditType === 'personal') {
            headers.push({text: `${exercise.text} (Результат)`, value: `exercises.${id}.value`, sortable: false});
          }

        })
      }

      if (this.form.rating === 'summary') {
        headers.push({text: 'Суммарный рейтинг', value: 'points_sum', sortable: true, width: '10%'},)
      } else if (this.form.rating === 'average') {
        headers.push({text: 'Средний рейтинг', value: 'average_sum', sortable: true, width: '10%'},)
      }

      this.table.headers = headers;

    },
    search: debounce(function () {
      this.table.options.page = 1;
      this.fetch()
    }, 500),
    optionsUpdated() {
      this.fetch()
    },
    toggleSelector(selector) {

      if (this.form[selector].length === this[selector].length) {
        this.form[selector]= [];
      } else {
        this.form[selector] = [];
        this[selector].forEach(x => {
          this.form[selector].push(x.id)
        })
      }

      this.fetch();
    },
    async exportToXlsx(){
      this.exportTo.loading = true;
      try {
        let response = await api.festival.tournamentTable.exportToXlsx(this.$route.params.id, {
          ...this.form, ...{
            search: this.form.search,
            sortBy: this.table.options.sortBy[0] || null,
            sortDesc: this.table.options.sortDesc[0] || null
          }
        });

        this.download(response.data.link, 'tournament-table.xlsx')

      } catch (e) {
        console.error(e);
      }

      this.exportTo.loading = false;

    },
    download(dataurl, filename) {
      let a = document.createElement('a');
      a.href = dataurl;
      a.setAttribute('download', filename);
      a.click();
    },
  },
}
</script>

<style scoped>

</style>
