import axios from 'axios';
// Либо берем переменную из окружения (при сборке), либо подставляем default значение.
// Переменная окружения нужна т.к. при работе в docker, api находится на отдельном порту.
// .env файл из которого берется переменная должен лежать в ./resources/js/app
const baseUrl = process.env.VUE_APP_BACKEND_URL || 'http://gto-festival-rework.test/'; //local-test
const API_PATH = `${baseUrl}api/`
const rootPath = '/'

const instance = axios.create({
    baseURL: API_PATH,
    headers: {
        'Content-Type': 'application/json',
    }
})

instance.interceptors.request.use(authInterceptor);
instance.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['XDEBUG_SESSION_START'] = process.env.VUE_APP_DEBUG_SESSION;
    return config;
});

instance.interceptors.response.use(null,errorInterceptor);
export const httpClient = instance;

function authInterceptor (config) {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return config;
}
function errorInterceptor(error) {
// check if it's a server error
    if (!error.response) {
        //notify.warn('Network/Server error');
        console.error('**Network/Server error');
        console.log(error.response);
        return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
        case 400:
            console.error(error.response.status, error.message);
            //notify.warn('Nothing to display', 'Data Not Found');
            break;

        case 401: // authentication error, logout the user
            //notify.warn('Please login again', 'Session Expired');
            console.error(error.response.status, error.message);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.replace(rootPath);
            break;

        default:
            console.error(error.response.status, error.message);
        //notify.error('Server Error');



    }
    return Promise.reject(error);
}


