<template>
    <v-app>
        <nav-left ref="nav"/>
        <v-main :style="styles">
          <v-card :class="isDesktop ? 'ma-10' : ''">
            <v-card-title> <v-btn
                v-if="isMobile && !goBackTo"
                slot="prepend-inner"
                class="mr-3"
                icon
                @click="toggleMenu"
            >
              <v-icon v-if="!goBackTo">mdi-menu</v-icon>
            </v-btn>
              <v-btn
                  v-if="goBackTo"
                  slot="prepend-inner"
                  class="mr-3"
                  icon
                  link
                  :to="(typeof goBackTo === 'object') ? goBackTo : { name: goBackTo}"
              >
              <v-icon>mdi-keyboard-backspace</v-icon>
              </v-btn>
              {{ title }}</v-card-title>
            <v-divider></v-divider>
            <router-view></router-view>
          </v-card>
        </v-main>
        <templates-sidebar-component></templates-sidebar-component>
        <notifications-component></notifications-component>
    </v-app>
</template>
<script>

import NavLeft from '@/components/common/NavLeft';
import {mapState, mapGetters} from 'vuex';
import NotificationsComponent from "@/components/common/notifications-component";
import TemplatesSidebarComponent from "@/views/Template/components/templates-sidebar-component";

export default {
    name: 'App',

    components: {
      TemplatesSidebarComponent,
      NotificationsComponent,
        NavLeft
    },
    data: () => ({
        isShowBar: true,
    }),
  computed: {
        ...mapState('app', [
            'miniVariant'
        ]),
        ...mapGetters('app', ['isMobile', 'isDesktop']),
        title() {
           return this.$route.meta.title;
        },
        goBackTo(){
          if (this.$route.meta.goBackTo && this.$route.meta.goBackTo.params) {
            let params = {}
            Object.keys(this.$route.meta.goBackTo.params).map((i) => {
              params[i] = this.$route.params[this.$route.meta.goBackTo.params[i].replace(':', '')];
            });
            return {name: this.$route.meta.goBackTo.name, params: params}
          }
          return this.$route.meta.goBackTo;
        },
        styles() {
            if (this.isDesktop) {
              return this.miniVariant
                  ? {background: '#E5E5E5', padding: '0 0 0 59px'}
                  : {background: '#E5E5E5', padding: '0 0 0 256px'};
            } else {
              return {background: '#E5E5E5', padding: '0 0 0 0'};
            }
        },
        isSvodLogsPage(){
          return this.$route.name === 'svod.logs.index'
        },
        showTemplatesSidebar(){
          return this.$route.name === 'festivals.exercises.index'
        }
    },
    methods: {
      toggleMenu(){
        this.$refs.nav.show = !this.$refs.nav.show
      }
    }
};
</script>
