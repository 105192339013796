<template>
  <v-navigation-drawer
      v-model="show"
      absolute
      temporary
      right
      width="400px"
  >

    <v-row class="pa-3 mt-0">
      <v-col cols="12" class="pt-0 pb-0">
        <h6 class="text-h6">Выбрать шаблон</h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row v-if="form.loading">
      <v-col cols="12" class="pt-0 pb-0">
        <div class="text-center pa-10">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="pa-3 mt-0">
        <v-col cols="12" class="pt-0 pb-0">
          <v-checkbox
              v-model="form.onlyMine"
              :label="`Только мои шаблоны`"
              hide-details
              @change="showSidebar()"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="pa-3 mt-0">
        <v-col cols="12" class="pb-0">
          <v-select
              :items="templates"
              item-value="id"
              item-text="name"
              filled
              label="Шаблон испытаний"
              v-model="form.template"
              return-object
              hide-details
              dense
          >
          </v-select>
        </v-col>
      </v-row>
      <div v-if="form.template">
        <v-row class="pa-3 pt-5">
          <v-col cols="6" class="pt-0 pb-0">
            <label>Автор шаблона</label>
            <p class="mb-0"><strong>{{ form.template.user.profile.fullname }}</strong></p>
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <label>Регион автора</label>
            <p class="mb-0"><strong>{{ form.template.user.region ? form.template.user.region.name : '-' }}</strong></p>
          </v-col>
        </v-row>
        <v-row class="pa-3">
          <v-col cols="6" class="pt-0 pb-0">
            <label>Мужских испытаний</label>
            <p class="mb-0"><strong>{{ form.template.male_exercises_count }}</strong></p>
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <label>Женских испытаний</label>
            <p class="mb-0"><strong>{{ form.template.female_exercises_count }}</strong></p>
          </v-col>
        </v-row>
        <v-row class="pa-3 pt-5">
          <v-col cols="12">
            <label class="mb-3 d-block">Испытания </label>
            <div class="exercise-card" v-for="item in availableExercises" :key="item.id">
              <p class="exercise-card__title">{{ item.exercise.title_ru }}</p>
              <p class="exercise-card__description">{{ getExerciseDescription(item) }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row class="pa-3 pt-0" v-if="notAvailableExercises.length > 0">
          <v-col cols="12">
            <label class="mb-3 d-block">Недоступные испытания
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      v-bind="attrs"
                      v-on="on"
                  >mdi-comment-question
                  </v-icon>
                </template>
                <span>Испытания из этого списка больше недействительны. Данные испытания не будут добавлены в фестиваль.</span>
              </v-tooltip>
            </label>
            <div class="exercise-card exercise-card--not-available" v-for="item in notAvailableExercises" :key="item.id">
              <p class="exercise-card__title">{{ item.exercise.title_ru }}</p>
              <p class="exercise-card__description">{{ getExerciseDescription(item) }}</p>
            </div>
          </v-col>
        </v-row>
      </div>


    </div>

    <template v-slot:append>
      <v-row class="pa-3">
        <v-col cols="12" >
          <v-btn color="primary" class="mr-3" :disabled="!form.template || form.loading" @click="showDialog()">Применить шаблон</v-btn>
          <v-btn color="primary" outlined text @click="cancel()">Отменить</v-btn>
        </v-col>
      </v-row>
    </template>

    <v-dialog
        v-model="dialog"
        persistent
        max-width="480"
    >
      <v-card>
        <v-card-title>
          Применить шаблон
        </v-card-title>
        <v-card-text>
          Это действие заменит список испытаний, которые уже выбраны. Вы уверены?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              outlined
              text
              @click="setupExercises()"
          >
            Да, применить
          </v-btn>
          <v-btn
              color="primary"
              @click="dialog = false"
          >
            Нет, отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import {api} from "@/api";
import {mapActions} from "vuex";

export default {
  name: "templates-sidebar-component",
  data() {
    return {
      show: false,
      templates: [],
      form: {
        loading: true,
        template: null,
        onlyMine: false,
      },
      dialog: false
    }
  },
  mounted() {
    this.$root.$on('show-templates-sidebar', () => {
      this.showSidebar()
    })
  },
  computed:{
    availableExercises(){
      return this.form.template.exercises.filter(x => x.exercise.visibility === true)
    },
    notAvailableExercises(){
      return this.form.template.exercises.filter(x => x.exercise.visibility === false)
    }
  },
  methods: {
    ...mapActions('app', ['notify']),
    async showSidebar() {
      try {
        this.show = true;
        this.templates = [];
        this.form.template = null;

        this.form.loading = true;
        let response = await api.template.all({only_mine: this.form.onlyMine, limit: 500});
        this.templates = response.data.list
        this.form.loading = false;
      } catch (e) {
        console.error(e)
      }
    },
    cancel(){
      this.show = false;
      this.form.template = null;
    },
    showDialog(){
      this.dialog = true;
    },
    setupExercises(){
      this.dialog = false;
      this.show = false;
      this.$root.$emit('setup-exercises', this.availableExercises);
    },
    getExerciseDescription(exercise){
      let categories = exercise.ageCategories.list.map(x => x.name).join(',');
      let genders = exercise.genders.list.map(x => x.name_ru).join(',');
      let priority = exercise.priority === 0 ? 'Нет приоритета' : `${exercise.priority} приоритет`
      return `${genders} / Категории ${categories} / ${priority}`;
    }
  }
}
</script>

<style scoped lang="scss">
  .exercise-card {
    background: #C5CAE9;
    border-radius: 2px;
    padding: 8px;
    margin-bottom: 8px !important;

    &--not-available {
      background: rgba(0, 0, 0, 0.12);

      .exercise-card__title {
        color: rgba(0, 0, 0, 0.38);
      }

      .exercise-card__description {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 4px !important;
    }

    &__description {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0px !important;
    }
  }
</style>