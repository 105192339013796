import store from '@/store';

export function guard(to, from, next){
    if (!store.getters['auth/isLoggedIn'] || !store._vm.$gates){
        next(`/login?redirect_url=${to.path}`);
        return;
    }
    console.info('Has permission to',to.name, store._vm.$gates.hasPermission(to.name))
    if (!store._vm.$gates.hasPermission(to.name)){
        next(`/festivals`);
        return;
    }

    next();
}

