<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="800px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
          <v-card-title>
            {{ title }}
            <p class="subtitle-2 mb-0" >Укажите желамые параметры, ссылка будет сгенерирована автоматически</p>
          </v-card-title>

          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-text-field
                      color="primary"
                      v-model="form.title"
                      filled
                      label="Название"
                      hide-details
                      :error-messages="errors"
                      :disabled="loading"
                      dense
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <label class="label ">Тип зачета</label>
                  <v-radio-group v-model="form.credit_type"
                                 :error-messages="errors"
                                 hide-details>
                    <v-radio
                        label="Личный"
                        value="personal"
                    ></v-radio>
                    <v-radio
                        :disabled="festival.settings.credit_type === 'personal'"
                        label="Командный и личный"
                        value="team"
                    ></v-radio>
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <label class="label">Тип рейтинга</label>
                  <v-radio-group v-model="form.rating"
                                 :error-messages="errors"
                                 hide-details>
                    <v-radio
                        label="Суммарный"
                        value="summary"
                    ></v-radio>
                    <v-radio
                        label="Cредний"
                        value="average"
                    ></v-radio>
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <label class="label">Пол</label>
                    <v-checkbox
                        label="Мужской"
                        v-model="form.genders"
                        :value="1"
                        hide-details
                        :error-messages="errors"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="form.genders"
                        label="Женский"
                        :value="2"
                        hide-details
                        :error-messages="errors"
                        class="mt-1"
                    ></v-checkbox>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-select
                      filled
                      :items="exercises"
                      label="Испытания"
                      :error-messages="errors"
                      v-model="form.exercises"
                      item-value="id"
                      item-text="text"
                      multiple
                      dense
                      hide-details

                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                          ripple
                          @click="toggleSelector('exercises')"
                      >
                        <v-list-item-action>
                          <v-icon v-if="form.exercises.length === exercises.length" color="primary">
                            mdi-checkbox-marked
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-outline
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Все испытания
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <span v-if="form.exercises.length === exercises.length && index < 1">Все испытания</span>
                      <span v-else-if="form.exercises.length !== exercises.length">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption"> + еще {{ form.exercises.length - 1 }}</span>
                </span>
                    </template>
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                    :rules="{ required: festival.settings.credit_type !== 'personal'}"
                    v-slot="{ errors }"
                >
                  <v-select
                      filled
                      :items="teams"
                      label="Команды"
                      :error-messages="errors"
                      v-model="form.teams"
                      item-value="id"
                      item-text="text"
                      multiple
                      dense
                      hide-details
                      :disabled="festival.settings.credit_type === 'personal'"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                          ripple
                          @click="toggleSelector('teams')"
                      >
                        <v-list-item-action>
                          <v-icon v-if="form.teams.length === teams.length" color="primary">
                            mdi-checkbox-marked
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-outline
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Все команды
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <span v-if="form.teams.length === teams.length && index < 1">Все команды</span>
                      <span v-else-if="form.teams.length !== teams.length">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption"> + еще {{ form.teams.length - 1 }}</span>
                </span>
                    </template>

                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-select
                      filled
                      :items="age_categories"
                      label="Возрастные группы"
                      :error-messages="errors"
                      v-model="form.age_categories"
                      item-value="id"
                      item-text="text"
                      multiple
                      dense
                      hide-details
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                          ripple
                          @click="toggleSelector('age_categories')"
                      >
                        <v-list-item-action>
                          <v-icon v-if="form.age_categories.length === age_categories.length" color="primary">
                            mdi-checkbox-marked
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-outline
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Все категории
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <span v-if="form.age_categories.length === age_categories.length && index < 1">Все категории</span>
                      <span v-else-if="form.age_categories.length !== age_categories.length">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption"> + еще {{ form.age_categories.length - 1 }}</span>
                </span>
                    </template>

                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                >
                  <v-select
                      filled
                      :items="display_members_count"
                      label="Количество участников на экране"
                      :error-messages="errors"
                      v-model="form.display_members_count"
                      item-value="id"
                      item-text="text"
                      dense
                      hide-details
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    color="primary"
                    filled
                    label="Ссылка"
                    hide-details
                    :disabled="true"
                    dense
                    v-model="form.link"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>


          <v-card-actions class="pa-4">
            <v-btn
                color="primary"
                :disabled="invalid || loading"
                @click="submit()"
                :loading="loading"
            >
              {{ buttonLabel }}
            </v-btn>

            <v-btn
                color="primary"
                outlined
                text
                @click="dialog = false"
                v-show="!loading"
            >
              Отменить
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {ValidationProvider, ValidationObserver, setInteractionMode} from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');
import errorHandler from '@/modules/errorHandler';

import {mapState, mapActions} from 'vuex';
import {api} from "@/api";


export default {
  name: 'create-edit-screen-component',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    festival: {
      type: Object,
      default:() => ({})
    },
    populateWith: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {
      title: null,
      link: null,
      credit_type: 'personal',
      rating: 'summary',
      genders: [1,2],
      exercises: [],
      age_categories: [],
      teams: [],
      display_members_count: 10,
    },
    genders: [{id: 1, text: 'Мужской'}, {id: 2, text: 'Женский'}],
    creditTypes: [{id: 'summary', text: 'Суммарный'}, { id: 'average', text: 'Средний' }],
    display_members_count: [{id: 10, text: 10}, {id: 20, text: 20}, {id: 50, text: 50}, {id: 0, text: 'Все'}, ]
  }),
  watch: {
    populateWith() {
      this.resetForm();
    },
    festival(){
      this.form.teams = this.teams.map(item => {
        return item.id
      });
      this.form.exercises = this.exercises.map(item => {
        return item.id
      });
      this.form.age_categories = this.age_categories.map(item => {
        return item.id
      });
      this.form.genders = this.genders.map(item => {
        return item.id
      });

      this.generateLink();
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('roles', ['roles']),
    teams(){
      if (this.festival){
        return this.festival.teams.list.map(team => {
          return {id: team.id, text: team.teamable.name}
        })
      }
      return [];
    },
    exercises(){
      if (this.festival){
        let exercises = new Map();
        this.festival.exercises.map(exercise => {
          exercises.set(exercise.exercise.id, {id: exercise.exercise.id, text: exercise.exercise.title_ru})
        })

        return Array.from(exercises.values());
      }
      return [];
    },
    age_categories(){
      if (this.festival) {
        let ageCategories = new Map();
        this.festival.exercises.map(exercise => {
          exercise.ageCategories.list.map(x => {
            ageCategories.set(x.id, {id: x.id, text: x.name})
          })
        });

        return  Array.from(ageCategories.values()).sort((a, b) => {
          if (a.id < b.id) return -1
          return a.id > b.id ? 1 : 0
        });
      }
      return [];
    },
    isEdit() {
      return (this.populateWith && Object.keys(this.populateWith).length > 0 && this.populateWith.constructor === Object)
    },
    title() {
      return this.isEdit ? 'Редактировать ссылку на испытание' : 'Создать ссылку на испытание'
    },
    buttonLabel() {
      return this.isEdit ? 'Сохранить' : 'Добавить'
    }
  },
  methods: {
    ...mapActions('app', ['notify', 'error']),
    async submit() {
      this.$refs.observer.validate().then(async (result) => {
        if (result) {
          this.loading = true;
          try {
            if (this.isEdit){
              await api.festival.screens.update(this.$route.params.id, this.populateWith.id, this.form);
              this.notify('Ссылка изменена')
            } else {
              await api.festival.screens.store(this.$route.params.id, this.form);
              this.notify('Ссылка создана')
            }

            this.$emit('saved');
          } catch (err) {
            this.error(err);
          }

          this.loading = false;
        }
      }).catch(errorHandler);
    },
    toggleSelector(selector) {
      if (this.form[selector].length === this[selector].length) {
        this.form[selector] = [];
      } else {
        this.form[selector] = [];
        this[selector].forEach(x => {
          this.form[selector].push(x.id)
        })
      }
    },
    resetForm() {
      Object.assign(this.$data.form, this.$options.data().form)

      if (this.isEdit) {
        this.form = this.populateWith;

        this.form.teams = this.populateWith.teams.map(item => {
          return item.id
        });
        this.form.exercises = this.populateWith.exercises.map(item => {
          return item.id
        });
        this.form.age_categories = this.populateWith.age_categories.map(item => {
          return item.id
        });
        this.form.genders = this.populateWith.genders.map(item => {
          return item.id
        });
      } else {
        this.form.teams = this.teams.map(item => {
          return item.id
        });
        this.form.exercises = this.exercises.map(item => {
          return item.id
        });
        this.form.age_categories = this.age_categories.map(item => {
          return item.id
        });
        this.form.genders = this.genders.map(item => {
          return item.id
        });
      }

      this.generateLink();
    },
    generateLink() {
      if (!this.form.link) {
        this.form.link = this.festival.link + '/rating/' + (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
      }
    }
  }
}
</script>

<style scoped>

</style>