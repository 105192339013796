import { render, staticRenderFns } from "./Svod.vue?vue&type=template&id=a885490c&scoped=true&"
import script from "./Svod.vue?vue&type=script&lang=js&"
export * from "./Svod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a885490c",
  null
  
)

export default component.exports