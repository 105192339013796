var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-view'),_c('v-row',{staticClass:"pa-3 pb-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6 mb-md-0",attrs:{"cols":"12"}},[_c('svod-upload-form-component'),_c('div',{staticClass:"mt-3 mb-3"})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{ref:"table",staticClass:"table",attrs:{"headers":_vm.table.headers,"items":_vm.table.list,"item-key":"id","loading":_vm.form.loading,"server-items-length":_vm.table.meta.total,"options":_vm.table.options,"footer-props":{
            itemsPerPageOptions : [10,25,50,-1]
          }},on:{"update:options":[function($event){return _vm.$set(_vm.table, "options", $event)},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"table-actions"},[_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.file)?_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","icon":"","href":item.file.url,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,true)},[_vm._v(" Скачать xlsx ")]),_c('v-tooltip',{attrs:{"left":"","content-class":"tooltip-gto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.canDelete(item))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" Удалить ")])],1)]}}])})],1)],1),_c('remove-item',{ref:"remover",attrs:{"populate-with":_vm.selected,"type":'svod'},on:{"remove-item-success":_vm.removed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }