<template>
  <div class="mb-3">
    <v-row class="pa-3 pb-0">
      <v-col class="pb-0">
        <h5 class="text-h6 pb-0">Участники</h5>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6"  class="pb-0">
        <label><strong>Тип зачета</strong></label>
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-radio-group
              v-model="form.credit_type"
              @change="checkFestivalTeams()"
              row
              :error-messages="errors"
              hide-details
          >
            <v-radio
                label="Личный"
                value="personal"
            ></v-radio>
            <v-radio
                label="Командный и личный"
                value="team_and_personal"
            ></v-radio>
          </v-radio-group>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6"  class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
        >
          <v-select
              :items="teamCategories"
              filled
              v-model="form.team_category"
              item-text="text"
              item-value="id"
              label="Категория команды"
              hide-details
              dense
              :error-messages="errors"
          >
          </v-select>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6"  class="pb-0">
        <label>
          <strong>Регистрация на фестиваль</strong></label>
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-radio-group
              v-model="form.registration_type"
              row
              :error-messages="errors"
              hide-details
          >

            <v-radio
                label="Самостоятельно"
                value="personal"
            ></v-radio>
            <v-radio
                label="Без регистрации"
                value="optional"
            ></v-radio>
          </v-radio-group>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" lg="4" md="6"  class="pb-0">

      </v-col>
    </v-row>

    <v-row class="pa-3 pb-0">
      <v-col cols="12" class="pb-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6"  class="pb-0">
        <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            :skip-if-empty="false"
        >
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >  <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="form.age_level_verification_date"
                label="Дата проверки возрастной ступени"
                prepend-inner-icon="mdi-calendar"
                prepend-icon=""
                filled
                v-bind="attrs"
                v-on="on"
                dense
                hide-details
                :disabled="(festival.is_active || festival.is_finished)"
            ></v-text-field>
          </template>
            <v-date-picker
                v-model="form.age_level_verification_date"
                :error-messages="errors"
            ></v-date-picker>
          </v-menu>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" class="pb-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="pa-3 pb-0">
      <v-col cols="12" lg="4" md="6" class="pb-0">
        <v-row class="pa-0">
          <v-col cols="6" class="pb-0">
            <image-upload-component :label="'Файл бейджа А6'" :height="'100%'" :file-data="form.badge_a6_file"
                                    :file.sync="form.badge_a6_file"></image-upload-component>
          </v-col>
          <v-col cols="6" class="pb-0">
            <label><strong>Цвет заголовка</strong></label>
            <ValidationProvider
                rules="required"
                :skip-if-empty="false"
            >
              <v-color-picker
                  dot-size="18"
                  mode="hexa"
                  canvas-height="50"
                  v-model="form.badge_a6_header_color"
              ></v-color-picker>
            </ValidationProvider>
            <label><strong>Цвет текста</strong></label>
            <ValidationProvider
                rules="required"
                :skip-if-empty="false"
            >
              <v-color-picker
                  dot-size="18"
                  mode="hexa"
                  canvas-height="50"
                  v-model="form.badge_a6_text_color"
              ></v-color-picker>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="4" md="6"  class="pb-0">

        <v-row class="pa-0">
          <v-col cols="6" class="pb-0">
            <image-upload-component :label="'Файл бейджа 6*9'" :height="'100%'" :file-data="form.badge_6x9_file" :file.sync="form.badge_6x9_file"></image-upload-component>
          </v-col>
          <v-col cols="6" class="pb-0">
            <label><strong>Цвет заголовка</strong></label>
            <ValidationProvider
                rules="required"
                :skip-if-empty="false"
            >
              <v-color-picker
                  dot-size="18"
                  mode="hexa"
                  canvas-height="50"
                  v-model="form.badge_6x9_header_color"
              ></v-color-picker>
            </ValidationProvider>
            <label><strong>Цвет текста</strong></label>
            <ValidationProvider
                rules="required"
                :skip-if-empty="false"
            >
              <v-color-picker
                  dot-size="18"
                  mode="hexa"
                  canvas-height="50"
                  v-model="form.badge_6x9_text_color"
              ></v-color-picker>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-3 mb-3">
      <v-col>
        <v-checkbox
            v-model="form.display_sponsors"
            label="Оставить название и поле для спонсоров пустыми"
            hide-details
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-dialog
        v-model="changeFestivalTypeDialog.show"
        persistent
        max-width="720"
    >
      <v-card>
        <v-card-title>
          В фестиваль уже добавлены команды.
        </v-card-title>
        <v-card-text>
          При изменении типа зачета, все текущие команды фестиваля будут удалены. Участники будут откреплены от команд.
          Продолжить?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"

              @click="changeFestivalTypeDialog.show = false"
          >
            Да
          </v-btn>
          <v-btn
              color="primary"
              outlined
              text
              @click="cancelChangeFestivalType()"

          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>

import {ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
import ImageUploadComponent from "@/components/common/image-upload-component";


export default {
name: 'festival-settings-form-component',
  components: {ImageUploadComponent, ValidationProvider },
  props: {
    festival:{
      type: Object,
      default: null
    },
    formData: {
      type: Object,
      default: function (){
        return {
          credit_type: 'team_and_personal',
          team_category: 'country',
          registration_type: 'optional',
          age_level_display: 'levels',
          age_level_verification_date: null,
          badge_a6_file: null,
          badge_a6_header_color: '#FF0000FF',
          badge_a6_text_color: '#FF0000FF',
          badge_6x9_file: null,
          badge_6x9_header_color: '#FF0000FF',
          badge_6x9_text_color: '#FF0000FF',
          display_sponsors: false
        }
      }
    }
  },
  data(){
    return {
      form: JSON.parse(JSON.stringify(this.formData)),
      teamCategories: [
        {id: 'country', text: 'Страна'},
        {id: 'region', text: 'Регион'},
        {id: 'municipality', text: 'Муниципальное образование'},
        {id: 'corporate', text: 'Корпоратив'},
        {id: 'school', text: 'Школа'},
        {id: 'other', text: 'Другое'},
      ],
      changeFestivalTypeDialog: {
        show: false
      }
    }
  },
  computed: {
  },
  methods: {
    checkFestivalTeams() {

      if (this.festival.teams && this.festival.teams.list && this.festival.teams.list.length > 0) {
        if (this.form.credit_type === 'personal' && this.festival.settings.credit_type === 'team_and_personal') {
          this.changeFestivalTypeDialog.show = true;
        }
      }

    },
    cancelChangeFestivalType() {
      this.form.credit_type = 'team_and_personal'
      this.changeFestivalTypeDialog.show = false
    }
  }
}
</script>

<style scoped>

</style>