<template>
  <div class="ma-2">
    <v-alert v-for="item in list"
             :key="item.id"
             border="left"
             :type="getStyle(item)"
             dense
             :dismissible="item.status === 'finished' || item.status === 'error'"
             @input="mark(item)"
    >
      {{ getMessage(item) }}
    </v-alert>
  </div>
</template>

<script>
import {api} from '@/api';

export default {
  name: 'festival-protocols-generations-component',

  timerId: null,
  data: function () {
    return {
      list: [],
    }
  },
  created() {
    this.fetch();

    this.$options.timerId = setInterval(() => {
      this.fetch()
    }, 20000)
  },
  beforeDestroy() {
    clearInterval(this.$options.timerId)
  },
  methods: {
    async fetch() {
      const response = await api.festival.protocols.generations.list()
      this.list = response.data.list.data;
    },
    async mark(item) {
      await api.festival.protocols.generations.mark(item.id);
    },
    getMessage(item) {
      switch (item.status) {
        case 'created':
          return `Фестиваль #${item.festival_id}. Идет генерация протоколов участников`
        case 'processing':
          return `Фестиваль #${item.festival_id}. Идет генерация протоколов участников.`
        case 'finished':
          return `Фестиваль #${item.festival_id}. Генерация протоколов успешно завершена.`
        case 'error':
          return `Фестиваль #${item.festival_id}. Произошла ошибка при генерации протоколов участников.`
      }
    },
    getStyle(item) {
      switch (item.status) {
        case 'created':
          return 'info'
        case 'processing':
          return 'info'
        case 'finished':
          return 'success'
        case 'error':
          return 'error'
      }
    }
  }
}
</script>

<style scoped>

</style>