import { render, staticRenderFns } from "./svod-info-comonent.vue?vue&type=template&id=2432d4ba&scoped=true&"
import script from "./svod-info-comonent.vue?vue&type=script&lang=js&"
export * from "./svod-info-comonent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2432d4ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
installComponents(component, {VBanner})
