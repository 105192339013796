<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-card class="mx-auto">
        <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
        >
        <v-card-title>
          {{ title }}
        </v-card-title>

        <v-card-text dense>
          <template>
            <v-row>
              <v-col
                  md="6"
                  cols="12"
              >
                <ValidationProvider
                    name="Название"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                <v-text-field
                    color="primary"
                    v-model="form.title_ru"
                    filled
                    label="Название"
                    dense
                    hide-details
                    :error-messages="errors"
                >
                </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col
                  md="6"
                  cols="12"
              >
                <ValidationProvider
                    name="Название (EN)"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-text-field
                      color="primary"
                      v-model="form.title_en"
                      filled
                      label="Название (EN)"
                      dense
                      hide-details
                      :error-messages="errors"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                    name="Описание"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                <v-textarea
                    color="primary"
                    v-model="form.description_ru"
                    filled
                    label="Описание"
                    auto-grow
                    dense
                    :error-messages="errors"
                    hide-details>
                </v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                    name="Описание (EN)"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-textarea
                      color="primary"
                      v-model="form.description_en"
                      filled
                      label="Описание (EN)"
                      auto-grow
                      dense
                      :error-messages="errors"
                      hide-details>
                  </v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  md="6"
                  cols="12"
              >
                <ValidationProvider
                    name="Параметр расчета"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-select filled
                            dense
                            label="Параметр расчета"
                            item-text="title"
                            item-value="id"
                            :items="parametersList"
                            v-model="form.exercise_parameter_id"
                            :disabled="isEdit"
                            hide-details
                            :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col
                  md="6"
                  cols="12"
              >
                <ValidationProvider
                    name="Еденица расчета"
                    rules="required"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-select filled
                            dense
                            hide-details
                            item-text="title"
                            item-value="id"
                            :items="unitsList"
                            v-model="form.exercise_unit_id"
                            :error-messages="errors"
                            :disabled="isEdit"
                            label="Единица расчета"></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider
                    name="Ссылка на видео"
                    v-slot="{ errors }"
                    mode="eager"
                >
                  <v-text-field
                      color="primary"
                      v-model="form.video_link"
                      filled
                      label="Ссылка на видео"
                      dense
                      hide-details
                      :error-messages="errors"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <image-upload-component :label="'Иконка'"
                                        ref="imageUploader"
                                        :disabled="loading"
                                        :height="'50px'"
                                        :width="'50px'"
                                        :file-data="form.icon"
                                        :file.sync="form.icon"></image-upload-component>
              </v-col>
            </v-row>
          </template>
        </v-card-text>


        <v-card-actions class="pa-4">
          <v-btn
              color="primary"
              :disabled="invalid || loading"
              @click="submit()"
              :loading="loading"
          >
            {{ buttonLabel }}
          </v-btn>

          <v-btn
              color="primary"
              outlined
              text
              @click="dialog = false"
              v-show="!loading"
          >
            Отменить
          </v-btn>
        </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate/dist/vee-validate.full.esm';

setInteractionMode('eager');
import errorHandler from '@/modules/errorHandler';

import {mapState,mapActions} from 'vuex';

import {api} from '@/api';
import ImageUploadComponent from "@/components/common/image-upload-component";

export default {
  name: 'create-edit-exercise',
  components: {
    ImageUploadComponent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
     populateWith: {
       type: Object,
       default: () => ({})
     },
    parametersList: {
       type: Array,
       default: () => ([])
    },
    unitsList: {
       type: Array,
       default: () => ([])
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {
      title_ru: null,
      title_en: null,
      description_ru: null,
      description_en: null,
      video_link: null,
      exercise_parameter_id: null,
      exercise_unit_id: null,
      icon: null
    }
  }),
  watch: {
    populateWith(){
      Object.assign(this.$data.form, this.$options.data().form)

      if (this.isEdit){
        this.form = JSON.parse(JSON.stringify(this.populateWith));
        this.form.exercise_parameter_id = this.populateWith.parameter.id;
        this.form.exercise_unit_id = this.populateWith.unit.id;
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    isEdit(){
      return (this.populateWith && Object.keys(this.populateWith).length > 0 && this.populateWith.constructor === Object)
    },
    title(){
      return this.isEdit ? 'Редактировать испытание' : 'Добавить испытание'
    },
    buttonLabel(){
      return this.isEdit ? 'Сохранить' : 'Добавить'
    }
  },
  methods: {
    ...mapActions('app', ['notify']),

    submit(){
      this.$refs.observer.validate().then(async (result)=>{
         if (result){
           this.loading = true;


           try {
             if (this.isEdit){
               await api.exercise.update(this.populateWith.id, this.form);
             } else {
               await api.exercise.store(this.form);
             }

             this.loading = false;
             this.dialog = false;

             this.$emit('submit-success');

             this.notify('Испытание ' + ((this.isEdit) ? 'сохранено' : 'добавлено'))

           } catch (err){
             console.error(err)
           }
         }
      }).catch(errorHandler);
    }

  }
}
</script>

<style scoped>

</style>